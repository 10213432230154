import accounting from "@/api/report/accounting";
import { baseState, baseMutations } from "@/store/mixins";
import { join } from "lodash";
export const state = {
    ...baseState
};

export const getters = {};

export const mutations = {
    ...baseMutations
};

export const actions = {
    // generate profit and loss
    profitAndLoss(context, attributes = {}) {
        return accounting.profitAndLoss({
            params: Object.assign(
                {
                    template: localStorage.getItem("langCode")
                        ? localStorage.getItem("langCode")
                        : "en"
                },
                attributes
            )
        });
    },
    // generate summary of active account
    summaryOfActiveAccount(context, attributes = {}) {
        return accounting.summaryOfActiveAccount({
            params: Object.assign(
                {
                    template: localStorage.getItem("langCode")
                        ? localStorage.getItem("langCode")
                        : "en"
                },
                attributes
            )
        });
    },
    // generate trial balance
    trialBalance(context, attributes = {}) {
        return accounting.trialBalance({
            params: Object.assign(
                {
                    template: localStorage.getItem("langCode")
                        ? localStorage.getItem("langCode")
                        : "en"
                },
                attributes
            )
        });
    },
    // generate balance sheet
    balanceSheet(context, attributes = {}) {
        return accounting.balanceSheet({
            params: Object.assign(
                {
                    template: localStorage.getItem("langCode")
                        ? localStorage.getItem("langCode")
                        : "en"
                },
                attributes
            )
        });
    },
    // generate general journal record
    generalJournalRecord(context, attributes = {}) {
        return accounting.generalJournalRecord({
            params: Object.assign(
                {
                    template: localStorage.getItem("langCode")
                        ? localStorage.getItem("langCode")
                        : "en",
                    filter: {
                        date_between: join(attributes.dateRange, ","),
                        currency_id: attributes.currency_id
                    }
                },
                attributes
            )
        });
    },
    // generate general ledger
    generalLedger(context, attributes = {}) {
        return accounting.generalLedger({
            params: Object.assign(
                {
                    template: localStorage.getItem("langCode")
                        ? localStorage.getItem("langCode")
                        : "en"
                },
                attributes
            )
        });
    },
    generalLedgerExportExcel(context, { ...attributes }) {
        return accounting.generalLedgerExportExcel(
            Object.assign(
                {
                    template: localStorage.getItem("langCode")
                        ? localStorage.getItem("langCode")
                        : "en"
                },
                attributes
            )
        );
    },
    generalJournalRecordExportExcel(context, { ...attributes }) {
        return accounting.generalJournalRecordExportExcel(
            Object.assign(
                {
                    template: localStorage.getItem("langCode")
                        ? localStorage.getItem("langCode")
                        : "en",
                    filter: {
                        date_between: join(attributes.dateRange, ","),
                        currency_id: attributes.currency_id
                    }
                },
                attributes
            )
        );
    },
    trialBalanceExportExcel(context, { ...attributes }) {
        return accounting.trialBalanceExportExcel(
            Object.assign(
                {
                    template: localStorage.getItem("langCode")
                        ? localStorage.getItem("langCode")
                        : "en"
                },
                attributes
            )
        );
    },
    balanceSheetExportExcel(context, { ...attributes }) {
        return accounting.balanceSheetExportExcel(
            Object.assign(
                {
                    template: localStorage.getItem("langCode")
                        ? localStorage.getItem("langCode")
                        : "en"
                },
                attributes
            )
        );
    },
    profitAndLossExportExcel(context, { ...attributes }) {
        return accounting.profitAndLossExportExcel(
            Object.assign(
                {
                    template: localStorage.getItem("langCode")
                        ? localStorage.getItem("langCode")
                        : "en"
                },
                attributes
            )
        );
    },
    summaryOfActiveAccountExportExcel(context, { ...attributes }) {
        return accounting.summaryOfActiveAccountExportExcel(
            Object.assign(
                {
                    template: localStorage.getItem("langCode")
                        ? localStorage.getItem("langCode")
                        : "en"
                },
                attributes
            )
        );
    },
    summaryOfActiveAccountRawDataCSV(context, { ...attributes }) {
        return accounting.summaryOfActiveAccountRawDataCSV(
            Object.assign(
                {
                    template: localStorage.getItem("langCode")
                        ? localStorage.getItem("langCode")
                        : "en"
                },
                attributes
            )
        );
    }
};
