import httpClient from "@/http-client";

export const get = (Options = {}) => {
    return httpClient
        .get("/api/eod", Options)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data));
};
export const rollbackHistory = (Options = {}) => {
    return httpClient
        .get("/api/eod/rollback-histories", Options)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data));
};

export const store = data => {
    return httpClient
        .post("/api/eod", data)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data));
};

export const close = data => {
    return httpClient
        .post("/api/eod/close", data)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data));
};

export default { get, rollbackHistory, store, close };
