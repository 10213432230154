import citizenshipStatistic from "@/api/corporate/citizenship-statistic";
import { baseState, baseMutations } from "@/store/mixins";

export const state = {
    ...baseState,
    provinces: [],
    districts: [],
    communes: []
};

export const getters = {};

export const mutations = {
    ...baseMutations,
    SET_PROVINCES(state, data) {
        state.provinces = data;
    },
    SET_DISTRICTS(state, data) {
        state.districts = data;
    },
    SET_COMMUNES(state, data) {
        state.communes = data;
    }
};

export const actions = {
    async fetch(context, attributes = {}) {
        const params = Object.assign(
            {
                page: context.state.pagination.page,
                perPage: context.state.pagination.perPage,
                filter: {
                    search: context.state.search
                }
            },
            attributes
        );
        return citizenshipStatistic.get({ params }).then(response => {
            context.commit("RECEIVE_RESOURCES", response);
            return Promise.resolve(response);
        });
    },

    async getProvince(context) {
        let response = await citizenshipStatistic.province();
        context.commit("SET_PROVINCES", response.data);
    },
    async getDistrict(context, province_code) {
        if (!province_code) return;
        let response = await citizenshipStatistic.district(province_code);
        context.commit("SET_DISTRICTS", response.data);
    },
    async getCommune(context, district_code) {
        if (!district_code) return;
        let response = await citizenshipStatistic.commune(district_code);
        context.commit("SET_COMMUNES", response.data);
    },
    exportExcel(context, data) {
        return citizenshipStatistic.exportExcel(
            Object.assign(
                {
                    template: localStorage.getItem('langCode')
                },
                data
            ))
    },
    importExcel(context, data){
        return citizenshipStatistic.importExcel(data)
    }
};
