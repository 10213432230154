import loanRepaymentDelete from "@/api/credit-operation/loan-repayment";

import { baseState, baseMutations } from "@/store/mixins";

export const state = {
    ...baseState
};

export const getters = {};

export const mutations = {
    ...baseMutations
};

export const actions = {
    fetch(context, attrubutes = {}) {
        let params = Object.assign(
            {
                page: context.state.pagination.page,
                perPage: context.state.pagination.perPage,
                filter: {
                    search: context.state.search
                }
            },
            attrubutes
        );
        return loanRepaymentDelete.getDeleted({ params }).then(response => {
            context.commit("RECEIVE_RESOURCES", response);
            return Promise.resolve(response);
        });
    }
};
