import { baseState, baseMutations } from '@/store/mixins'
import fixedIncentiveBonus from '@/api/payroll/fixed-incentive-bonus'

export const state = {
    ...baseState,
    employeeProfiles: [],
    payItemIncentiveBonuses: []
}
export const getters = {}
export const mutations = {
    ...baseMutations,
    SET_EMPLOYEE_PROFILES (state, employeeProfiles) {
        state.employeeProfiles = employeeProfiles
    },
    SET_PAY_ITEM_ACENTIVE_BONUS (state, payItemIncentiveBonuses) {
        state.payItemIncentiveBonuses = payItemIncentiveBonuses
    }
}
export const actions = {
    async getEmployeeProfile (context, attributes = {}) {
        let response = await fixedIncentiveBonus.employeeProfile({
            params: attributes
        })
        context.commit('SET_EMPLOYEE_PROFILES', response.data)
    },
    async grtPayItemAcentiveBonus (context, attributes = {}) {
        let response = await fixedIncentiveBonus.payItemAcentiveBonus({
            params: attributes
        })
        context.commit('SET_PAY_ITEM_ACENTIVE_BONUS', response.data)
    },
    store (context, data) {
        return fixedIncentiveBonus.store(data)
    },
    fetch (context, attributes = {}) {
        const params = Object.assign(
            {
                page: context.state.pagination.page,
                perPage: context.state.pagination.perPage,
                filter: {
                    search: context.state.search
                }
            },
            attributes
        )
        return fixedIncentiveBonus.get({ params }).then(response => {
            context.commit('RECEIVE_RESOURCES', response)
            return Promise.resolve(response)
        })
    },
    update (context, { id, data }) {
        return fixedIncentiveBonus.update(id, data)
    },
    destroy (context, id) {
        return fixedIncentiveBonus.destroy(id)
    }
}
