import payrollTemplate from "@/api/payroll/payroll-template";
import { baseState, baseMutations } from "@/store/mixins";

export const state = {
    ...baseState,
    userVariables: []
};

export const getters = {};

export const mutations = {
    ...baseMutations,
    SET_VARIABLE(state, variable) {
        state.userVariables = variable;
    }
};

export const actions = {
    fetch(context, attributes = {}) {
        const params = Object.assign(
            {
                page: context.state.pagination.page,
                perPage: context.state.pagination.perPage,
                filter: {
                    search: context.state.search
                }
            },
            attributes
        );
        return payrollTemplate.get({ params }).then(response => {
            context.commit("RECEIVE_RESOURCES", response);
            return Promise.resolve(response);
        });
    },

    store(context, data) {
        return payrollTemplate.store(data);
    },

    destroy(context, id) {
        return payrollTemplate.destroy(id);
    },

    update(context, { id, data }) {
        return payrollTemplate.update(id, data);
    },

    design(context, { id, override }) {
        const params = Object.assign({}, { override: override });
        return payrollTemplate.design(id, { params });
    },

    uploadTemplate(context, { id, data }) {
        return payrollTemplate.uploadTemplate(id, data);
    },

    async variable(context, attributes = {}) {
        let response = await payrollTemplate.variable({ params: attributes });
        context.commit("SET_VARIABLE", response.data);
        return Promise.resolve(response.data);
    }
};
