import httpClient from "@/http-client";

export const store = data => {
    return httpClient
        .post("/api/basic-salarys", data)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data));
};
export const employeeProfile = (Option = {}) => {
    return httpClient
        .get("/api/basic-salarys/employee-profiles", Option)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data));
};
export const get = (options = {}) => {
    return httpClient
        .get("/api/basic-salarys", options)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data));
};
export const update = (id, data) => {
    return httpClient
        .put(`/api/basic-salarys/${id}`, data)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data));
}
export const destroy = id => {
    return httpClient
        .delete(`/api/basic-salarys/${id}`)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data));
};
export const formViewModel = (options = {}) => {
    return httpClient
        .get("/api/basic-salarys/form-view-models", options)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data));
};
export const exportExcel = attributes => {
    return httpClient
        .post(
            "/api/basic-salarys/export", attributes,
            { responseType: "arraybuffer" }
        )
        .then(response => Promise.resolve(response))
        .catch(error => Promise.reject(error.response.data));
};
export const importExcel = data => {
    return httpClient
        .post('/api/basic-salarys/import-excels', data)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data))
}
export default { store, employeeProfile, get, update, destroy, formViewModel, exportExcel, importExcel }
