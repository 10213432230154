import httpClient from '@/http-client'

export const view = (Option = {}) => {
    return httpClient
        .get('/api/reports/payslips', Option)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data))
}
export const payslipTemplate = (Option = {}) => {
    return httpClient
        .get('/api/reports/payslips/payslip-templates', Option)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data))
}
export const formViewModel = (options = {}) => {
    return httpClient
        .get('/api/reports/payslips/form-view-models', options)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data))
}
export const employeeProfile = id => {
    return httpClient
        .get(`/api/reports/payslips/employees/${id}`, Option)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data))
}
export const exportExcel = (templateId, attributes = {}) => {
    return httpClient
        .get(`/api/reports/payslips/export-excel`, {
            params: { template_id: templateId, ...attributes },
            responseType: 'arraybuffer'
        })
        .then(response => Promise.resolve(response))
        .catch(error => Promise.reject(error.response.data))
}
export const previewPdf = data => {
    return httpClient
        .get('/api/reports/payslips/preview-pdf', data)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data))
}

export default {
    payslipTemplate,
    formViewModel,
    employeeProfile,
    view,
    previewPdf,
    exportExcel
}
