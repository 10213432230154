import httpClient from "@/http-client";

export const get = (options = {}) => {
    return httpClient
        .get("/api/work-schedules", options)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data));
};

export const store = data => {
    return httpClient
        .post("/api/work-schedules", data)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data));
};

export const copy = data => {
    return httpClient
        .post("/api/work-schedules/copies", data)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data));
};

export const shift = (Option = {}) => {
    return httpClient
        .get("/api/work-schedules/shifts", Option)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data));
};

export const getSpecialKeyword = (options = {}) => {
    return httpClient
        .get("/api/work-schedules/special-keywords", options)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data));
};

export const exportExcel = attributes => {
    return httpClient
        .post("/api/work-schedules/excel", attributes, {
            responseType: "arraybuffer"
        })
        .then(response => Promise.resolve(response))
        .catch(error => Promise.reject(error.response.data));
};

export default {
    get,
    store,
    shift,
    getSpecialKeyword,
    copy,
    exportExcel
};
