export default {
  name: 'TsPanel',
  functional: true,
  render(h, context) {
    return (
      <div
        {...{
          class: [
            'tw-bg-white tw-rounded-md tw-shadow',
            context.data.staticClass,
            context.data.class
          ],
          style: context.data.staticStyle,
          attrs: context.data.attrs,
          directives: context.data.directives
        }}
      >
        {context.children}
      </div>
    )
  }
}
