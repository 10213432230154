import eod from "@/api/credit-operation/eod";

import { baseState, baseMutations } from "@/store/mixins";

export const state = {
    ...baseState
};

export const getters = {};

export const mutations = {
    ...baseMutations
};

export const actions = {
    fetch(context, attrubutes = {}) {
        let params = Object.assign(
            {
                page: context.state.pagination.page,
                perPage: context.state.pagination.perPage
            },
            attrubutes
        );
        return eod.get({ params }).then(response => {
            context.commit("RECEIVE_RESOURCES", response);
            return Promise.resolve(response);
        });
    },
    store(context, data) {
        return eod.store(data);
    },
    close(context, data) {
        return eod.close(data);
    }
};
