import basicSalaryFormula from "@/api/human-resource/basic-salary-formula";
import { baseState, baseMutations } from "@/store/mixins";


export const state = {
    ...baseState,
    basicSalaryFormulaParameters:[]

};
export const getters={};

export const mutations ={
    ...baseMutations,
    SET_BASICSALARYFORMULAPARAMETERS(state,basicSalaryFormulaParameters){
        state.basicSalaryFormulaParameters = basicSalaryFormulaParameters
    },
    CLEAR_STATE(state){
        state.basicSalaryFormulaParameters = []
        state.edit_data = {}
    }
};
export const actions ={
    fetch(context, attributes = {}) {
        const params = Object.assign(
            {
                page: context.state.pagination.page,
                perPage: context.state.pagination.perPage,
                filter: {
                    search: context.state.search
                }
            },
            attributes
        );
        return basicSalaryFormula.get({ params }).then(response => {
            context.commit("RECEIVE_RESOURCES", response);
            return Promise.resolve(response);
        });
    },
    async getBasicSalaryFormulaParameter(context, attributes = {}) {
        let response = await basicSalaryFormula.basicSalaryFormulaParameter({ params: attributes });
        context.commit("SET_BASICSALARYFORMULAPARAMETERS", response.data);
    },
    update(context, { id, data }) {
        return basicSalaryFormula.update(id, data);
    },
}
