import jorunalEntry from "@/api/accounting/journal-entry";

import { baseState, baseMutations } from "@/store/mixins";
import { join } from "lodash";

export const state = {
    ...baseState,
    coaCategories: [],
    currencies: [],
    transactionTypes: [],
};

export const getters = {};

export const mutations = {
    ...baseMutations,
    SET_COA_CATEGORIES(state, data) {
        state.coaCategories = data;
    },
    SET_CURRENCIES(state, data) {
        state.currencies = data;
    },
    SET_TRANSACTION_TYPES(state, data) {
        state.transactionTypes = data;
    },
    CLEAR_STATE(state) {
        state.coaCategories = [];
        state.currencies = [];
        state.transactionTypes = [];
    }
};

export const actions = {
    fetch(context, attributes = {}) {
        let params = Object.assign(
            {
                page: context.state.pagination.page,
                perPage: context.state.pagination.perPage,
                filter: {
                    search: context.state.search,
                    date_between: join(attributes.dateRange, ",")
                }
            },
            attributes
        );
        return jorunalEntry.get({ params }).then(response => {
            context.commit("RECEIVE_RESOURCES", response);
            return Promise.resolve(response);
        });
    },
    store(context, data) {
        return jorunalEntry.store(data);
    },
    update(context, { id, data }) {
        return jorunalEntry.update(id, data);
    },
    destroy(context, { id, data }) {
        return jorunalEntry.destroy(id, data);
    },
    async getCurrency(context, attributes = {}) {
        let response = await jorunalEntry.currency({ params: attributes });
        context.commit("SET_CURRENCIES", response.data);
    },
    async getCoaCategory(context, attributes = {}) {
        let response = await jorunalEntry.coaCategory({ params: attributes });
        context.commit("SET_COA_CATEGORIES", response.data);
    },
    getCoaSetup(context, attributes = {}) {
        let params = Object.assign({
            filter: {
                search: attributes.search,
                acc_cat_id: attributes.acc_cat_id,
                currency_id: attributes.currency_id
            }
        });
        return jorunalEntry.coaSetup({ params });
    },
    async getTransactionType(context, attributes = {}) {
        let response = await jorunalEntry.transactionType({ params: attributes });
        context.commit("SET_TRANSACTION_TYPES", response.data);
    },
    refresh(context, data) {
        return jorunalEntry.refresh(data);
    },
};
