var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"tw-flex tw-justify-end tw-mb-4"},[_c('Input',{style:(_vm.width),attrs:{"search":"","placeholder":_vm.$t('branch.search')},on:{"on-focus":function () { return (_vm.width = 'width: 500px'); },"on-blur":function () { return (_vm.width = 'width: 300px'); }},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('Table',{attrs:{"columns":_vm.columns,"data":_vm.resources,"size":"small","loading":_vm.loading,"max-height":"700","stripe":""},scopedSlots:_vm._u([{key:"contract_expiry_date",fn:function(ref){
var row = ref.row;
return [_c('span',{staticClass:"tw-font-bold badge bg-warning"},[_vm._v(" "+_vm._s(row.contract_expiry_date)+" ")])]}},{key:"contract_period",fn:function(ref){
var row = ref.row;
return [(row.contract_period)?_c('span',[_vm._v(" "+_vm._s(row.contract_period > 1 ? row.contract_period + " Months" : row.contract_period + " Month")+" ")]):_vm._e()]}},{key:"monthly_fee",fn:function(ref){
var row = ref.row;
return [_c('span',{staticClass:"tw-font-bold text-success"},[_vm._v(" "+_vm._s(_vm.formatCurrencyWithCode(row.monthly_fee, row.currency))+" ")])]}},{key:"deposit_amount",fn:function(ref){
var row = ref.row;
return [_c('span',{staticClass:"tw-font-bold text-blue"},[_vm._v(" "+_vm._s(_vm.formatCurrencyWithCode(row.deposit_amount, row.currency))+" ")])]}},{key:"rental_contract_attachment",fn:function(ref){
var row = ref.row;
return [(row.rental_contracts.length > 0)?_c('span',_vm._l((row.rental_contracts),function(rc,index){return _c('span',{key:index},[(rc.rental_contract_attachment.length > 0)?_c('span',{staticClass:"tw-font-bold text-info"},[_c('ul',_vm._l((rc.rental_contract_attachment),function(item,key){return _c('li',{key:key,staticClass:"tw-cursor-pointer tw-border-double tw-border-4 tw-border-sky-500",on:{"click":function($event){return _vm.downloadFile(item)}}},[_c('i',{staticClass:"fas fa-download"}),_vm._v(" "+_vm._s(item.attached_file_name)+" ")])}),0)]):_vm._e()])}),0):_vm._e()]}},{key:"address",fn:function(ref){
var row = ref.row;
return [(_vm.$root.$i18n.locale === 'en')?_c('span',[_vm._v(_vm._s(row.vil_name_en ? row.vil_name_en + ", " : "")+" "+_vm._s(row.com_name_en ? row.com_name_en + ", " : "")+" "+_vm._s(row.dis_name_en ? row.dis_name_en + ", " : "")+" "+_vm._s(row.pro_name_en ? row.pro_name_en + "." : "-")+" ")]):_c('span',[_vm._v(_vm._s(row.vil_name_kh ? row.vil_name_kh + ", " : "")+" "+_vm._s(row.com_name_kh ? row.com_name_kh + ", " : "")+" "+_vm._s(row.dis_name_kh ? row.dis_name_kh + ", " : "")+" "+_vm._s(row.pro_name_kh ? row.pro_name_kh + "." : "-")+" ")])]}}])}),_c('div',{staticClass:"tw-flex tw-items-center tw-justify-end tw-p-4"},[_c('ts-i-pagination',{model:{value:(_vm.pagination),callback:function ($$v) {_vm.pagination=$$v},expression:"pagination"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }