<template>
    <div>
        <Page
            :total="value.total"
            :current="value.currentPage"
            :page-size="value.perPage"
            :show-sizer="showSizer"
            :page-size-opts="perPageOpts"
            show-total
            @on-change="val => onChangePage(val)"
            @on-page-size-change="val => onChangePerPage(val)"
        />
    </div>
</template>

<script>
export default {
    name: "TsIPagination",
    props: {
        value: {
            required: true
        },
        showSizer: {
            default: true,
            type: Boolean
        }
    },
    data() {
        return {
            perPageOpts: [15, 30, 50, 100, 200],
            currentPage: null,
            from: null,
            to: null,
            lastPage: null,
            perPage: null,
            total: null,
            loading: false
        };
    },
    watch: {
        value: function(value) {
            this.setData(value);
        }
    },
    created() {
        this.setData(this.value);
    },
    methods: {
        navigate(page) {
            this.$emit("navigate", {
                page,
                perPage: this.perPage
            });
        },
        onChangePage(currentPage) {
            this.$emit("navigate", {
                page: currentPage,
                perPage: this.perPage
            });
        },
        onChangePerPage(perPage) {
            this.currentPage = 1;
            this.$emit("navigate", {
                page: this.currentPage,
                perPage
            });
        },
        setData(paging) {
            if (paging != null) {
                this.currentPage = paging.currentPage;
                this.from = paging.from;
                this.to = paging.to;
                this.lastPage = paging.lastPage;
                this.perPage = paging.perPage;
                this.total = paging.total;
            }
        }
    }
};
</script>
