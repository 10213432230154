<template>
  <div class="form-check">
    <input
      type="checkbox"
      :checked="isChecked"
      :disabled="disabled"
      :value="value"
      @change="event => onChange(event)"
      class="form-check-input"
    />
    <label @click="event => event.target.previousSibling.click()">
      <slot />
    </label>
  </div>
</template>

<script>
import { findIndex, isEqual, cloneDeep } from 'lodash'

export default {
  name: 'TsCheckbox',
  model: {
    prop: 'checkedValue',
    event: 'change'
  },
  props: {
    value: {},
    checkedValue: {
      type: [Boolean, Array],
      default: false
    },
    disabled: Boolean
  },
  computed: {
    isChecked() {
      return (
        (Array.isArray(this.checkedValue) &&
          findIndex(this.checkedValue, o => isEqual(o, this.value)) >= 0) ||
        this.checkedValue === true
      )
    }
  },
  methods: {
    onChange(event) {
      if (Array.isArray(this.checkedValue)) {
        let checkedValue = cloneDeep(this.checkedValue)

        if (event.target.checked) {
          checkedValue.push(this.value)
        } else {
          checkedValue.splice(checkedValue.indexOf(this.value), 1)
        }

        this.$emit('change', checkedValue)
      } else {
        this.$emit('change', event.target.checked)
      }
    }
  }
}
</script>
