<template>
    <!-- class="tw-min-w-full tw-divide-y tw-divide-gray-200 tw-rounded-lg" -->
    <table class="table table-hover ui-table tw-table">
        <slot name="thead">
            <thead class="tw-bg-gray-200">
                <tr>
                    <ExpandableHead v-if="expandable" />
                    <slot name="columns">
                        <th
                            v-for="(column, key) in columns"
                            :key="key"
                            scope="col"
                            class="tw-px-6 tw-py-3 tw-text-left tw-font-bold tw-tracking-wider tw-whitespace-nowrap tw-top-0 tw-sticky"
                            :style="showColumnStyle(column)"
                            :class="showColumnClass(column)"
                        >
                            {{ showColumnName(column) }}
                            <ColumnFilter
                                :column="column"
                                :sortedColumn="sortedColumn"
                                @sort="setSortedColumn"
                            />
                        </th>
                    </slot>
                </tr>
                <TableProgressBar v-show="loading" />
            </thead>
        </slot>
        <slot name="tbody">
            <tbody>
                <template v-for="(items, itemKey) in dataTable">
                    <ShouldGroup
                        :items="items"
                        :itemKey="itemKey"
                        v-slot:group="{ record, index }"
                        :key="itemKey"
                    >
                        <tr
                            :key="keyName !== null ? record[keyName] : index"
                            @click="raiseRowClickEvent({ index, record })"
                            @dblclick="
                                raiseRowDoubleClickEvent({ index, record })
                            "
                            :style="{ cursor: mouseTypeOnRow }"
                        >
                            <CheckableColumn
                                :record="record"
                                v-if="showCheckbox"
                            />
                            <ExpandableColumn
                                :index="index"
                                v-if="expandable"
                            />
                            <slot :record="record" :index="index"></slot>
                        </tr>
                        <ExpandableRow v-if="expandableItems[index]">
                            <slot
                                name="expand"
                                :record="record"
                                :index="index"
                            ></slot>
                        </ExpandableRow>
                    </ShouldGroup>
                </template>
            </tbody>
        </slot>
        <slot name="tfooter"></slot>
    </table>
</template>

<script>
import { sortBy, debounce, transform, groupBy, reduce } from 'lodash'
// import CheckableHead from "./CheckableHead.vue";
import ExpandableHead from './ExpandableHead.vue'
import CheckableColumn from './CheckableColumn.vue'
import ExpandableColumn from './ExpandableColumn.vue'
import ExpandableRow from './ExpandableRow.vue'
import ShouldGroup from './ShouldGroup.vue'
import ColumnFilter from './ColumnFilter'
import TableProgressBar from './TableProgressBar'

export default {
    name: 'TsTable',
    components: {
        // CheckableHead,
        ExpandableHead,
        CheckableColumn,
        ExpandableColumn,
        ExpandableRow,
        ShouldGroup,
        ColumnFilter,
        TableProgressBar
    },
    props: {
        columns: {
            type: Array,
            default: () => []
        },
        records: {
            type: Array,
            default: () => []
        },
        showCheckbox: {
            type: Boolean,
            default: false
        },
        checkableCondition: {
            default: null
        },
        keyName: {
            type: String,
            default: null
        },
        expandable: {
            type: Boolean,
            default: false
        },
        rowGroup: {
            type: Object
        },
        loading: {
            type: Boolean,
            default: false
        },
        mouseTypeOnRow: {
            type: String,
            default: 'default'
        }
    },
    data () {
        return {
            dataTable: [],
            checkedItems: [],
            allItemsChecked: false,
            expandableItems: [],
            sortedColumn: null
        }
    },
    computed: {
        checkableItems () {
            return this.records.filter(item => this.shouldShowBox(item))
        },
        columnLength () {
            let length = this.columns.length

            if (this.showCheckbox) {
                ++length
            }

            if (this.expandable) {
                ++length
            }

            return length
        }
    },
    created () {
        this.records.forEach((item, index) => {
            this.expandableItems[index] = false
        })
    },

    methods: {
        getDataTable () {
            let dataTable = this.getSortDataTable()

            if (this.rowGroup !== undefined) {
                dataTable = transform(
                    groupBy(dataTable, this.iterateeGroupBy),
                    (result, value, key) => {
                        result[key] = {
                            data: value,
                            expand: true
                        }
                    },
                    {}
                )
            }

            return dataTable
        },
        getSortDataTable () {
            const nestedColumnValueReducer = (carrier, accumulator) => {
                return reduce(
                    carrier,
                    (carry, key) => {
                        if (carry === null || carry === undefined) return

                        return carry[key]
                    },
                    accumulator
                )
            }

            if (this.sortedColumn !== null) {
                try {
                    if (this.sortedColumn.order === 1) {
                        return sortBy(this.records, o => {
                            let sortVal = nestedColumnValueReducer(
                                this.sortedColumn.sortKey.split('.'),
                                o
                            )

                            if (sortVal === undefined) throw 'No sort data'

                            return sortVal
                        })
                    }

                    if (this.sortedColumn.order === -1) {
                        return sortBy(this.records, o => {
                            let sortVal = nestedColumnValueReducer(
                                this.sortedColumn.sortKey.split('.'),
                                o
                            )

                            if (sortVal === undefined) throw 'No sort data'

                            return sortVal
                        }).reverse()
                    }
                } catch (e) {
                    console.error(e)
                }
            }

            return this.records
        },
        iterateeGroupBy (record) {
            const itemKeys = this.rowGroup.field.split('.')

            return reduce(itemKeys, (item, key) => item[key], record)
        },
        toggleCheckingAllItems () {
            if (this.allItemsChecked === true) {
                this.checkedItems = this.checkableItems
            } else {
                this.checkedItems = []
            }
        },
        checkItem () {
            this.allItemsChecked =
                this.checkedItems.length === this.records.length
        },
        raisedCheckItemsEvent () {
            this.$emit('check-items', this.checkedItems)
        },
        shouldShowBox (item) {
            if (this.checkableCondition === null) {
                return true
            }

            return (
                item[this.checkableCondition[0]] == this.checkableCondition[1]
            )
        },
        toggleExpandedRow (index) {
            this.$set(this.expandableItems, index, !this.expandableItems[index])
        },
        showColumnName (column) {
            if (column instanceof Object) {
                return column.name
            }

            return column
        },
        showColumnStyle (column) {
            if (column instanceof Object) {
                return column.style
            }

            return {}
        },
        showColumnClass (column) {
            if (column instanceof Object) {
                return column.class
            }
        },
        filterable (column) {
            return column instanceof Object && column.filter !== undefined
        },
        getNestedPropertyValue (item, propsString) {
            let props = propsString.split('.'),
                temp = item[props[0]]

            for (let i = 1; i < props.length; i++) {
                temp = temp[props[i]]
            }

            return temp
        },
        setSortedColumn (sortedColumn) {
            this.sortedColumn = sortedColumn
        },
        fireFilterEvent: debounce(function ($event, $filterName) {
            this.$emit($filterName, $event.target.value)
        }, 1000),
        raiseRowClickEvent ({ index, record }) {
            this.$emit('row-click', { index, record })
        },
        raiseRowDoubleClickEvent ({ index, record }) {
            this.$emit('row-dblclick', { index, record })
        }
    },
    watch: {
        checkedItems: function () {
            this.raisedCheckItemsEvent()
        },
        records: {
            handler () {
                this.dataTable = this.getDataTable()
            },
            deep: true,
            immediate: true
        },
        sortedColumn: {
            handler () {
                this.dataTable = this.getDataTable()
            },
            deep: true,
            immediate: true
        }
    }
}
</script>

<style scoped>
table thead {
    color: #515a6e;
}
</style>
