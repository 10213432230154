import httpClient from "@/http-client";

export const get = (Options = {}) => {
    return httpClient
        .get("/api/coa-mappings", Options)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data));
};

export const store = data => {
    return httpClient
        .post("/api/coa-mappings", data)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data));
};

export const update = (id, data) => {
    return httpClient
        .put(`/api/coa-mappings/${id}`, data)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data));
};

export const destroy = (id, data) => {
    return httpClient
        .put(`/api/coa-mappings/${id}/delete`, data)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data));
};

export const loanType = (Option = {}) => {
    return httpClient
        .get("/api/coa-mappings/loan-types", Option)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data));
};

export const transactionType = (Option = {}) => {
    return httpClient
        .get("/api/coa-mappings/transaction-types", Option)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data));
};

export const currency = (Option = {}) => {
    return httpClient
        .get("/api/coa-mappings/currencies", Option)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data));
};

export const coaCategory = (Option = {}) => {
    return httpClient
        .get("/api/coa-mappings/coa-categories", Option)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data));
};

export const coaSetup = (Option = {}) => {
    return httpClient
        .get("/api/coa-mappings/coa-setups", Option)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data));
};

export const sourceValue = (Option = {}) => {
    return httpClient
        .get("/api/coa-mappings/source-values", Option)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data));
};

export default { get, store, update, destroy, loanType, transactionType, currency, coaCategory, coaSetup, sourceValue };
