import WorkSchedule from '@/api/human-resource/work-schedule'

import { baseState, baseMutations } from '@/store/mixins'

export const state = {
  ...baseState,
  edit_data: {},
  shifts: [],
  special_keywords: []
}

export const getters = {}

export const mutations = {
  ...baseMutations,
  SET_SHIFTS(state, data) {
    state.shifts = data
  },
  SET_SPECIAL_KEYWORDS(state, data) {
    state.special_keywords = data
  }
}

export const actions = {
  fetch(context, attrubutes = {}) {
    let params = Object.assign(
      {
        page: context.state.pagination.page,
        perPage: context.state.pagination.perPage,
        search: context.state.search
      },
      attrubutes
    )
    return WorkSchedule.get({ params }).then(response => {
      return Promise.resolve(response)
    })
  },

  store(context, data) {
    return WorkSchedule.store(data)
  },

  copy(context, data) {
    return WorkSchedule.copy(data)
  },

  getSpecialKeyword(context, id) {
    let params = Object.assign({}, { shift_id: id })
    WorkSchedule.getSpecialKeyword({ params }).then(response => {
      context.commit('SET_SPECIAL_KEYWORDS', response)
    })
  },

  async getShift(context, attributes = {}) {
    let response = await WorkSchedule.shift({
        params: attributes
    });
    context.commit("SET_SHIFTS", response.data);
},

  exportExcel(context, { ...attributes }) {
    return WorkSchedule.exportExcel(attributes)
  }
}
