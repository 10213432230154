import httpClient from "@/http-client";

export const get = (options = {}) => {
    return httpClient
        .get("/api/branches", options)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data));
};

export const getExpireContract = (options = {}) => {
    return httpClient
        .get("/api/branches/expire/contracts", options)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data));
};

export const store = data => {
    return httpClient
        .post("/api/branches", data)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data));
};

export const find = (id, options = {}) => {
    return httpClient
        .get(`/api/branches/${id}`, options)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data));
};

export const update = (id, data) => {
    return httpClient
        .put(`/api/branches/${id}`, data)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data));
};

export const destroy = id => {
    return httpClient
        .delete(`/api/branches/${id}`)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data));
};

export const removeContract = id => {
    return httpClient
        .delete(`/api/branches/rental-contracts/${id}`)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data));
};

export const updateContract = (id, data) => {
    return httpClient
        .put(`/api/branches/rental-contracts/${id}`, data)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data));
};

export const uploadLogo = file => {
    return httpClient
        .post("/api/branches/logos", file)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data));
};

export const removeFile = (Options = {}) => {
    return httpClient
        .get("/api/branches/remove-files", Options)
        .then(response => Promise.resolve(response.data))
        .catch(response => Promise.reject(response.data));
};

export const getLogo = id => {
    return httpClient
        .get(`/api/branches/${id}/logos`, {
            responseType: "arraybuffer"
        })
        .then(response =>
            Promise.resolve(
                `data:image/jpg;base64,${new Buffer(
                    response.data,
                    "binary"
                ).toString("base64")}`
            )
        )
        .catch(error => Promise.reject(error.response.data));
};

export const company = () => {
    return httpClient
        .get("/api/branches/companies")
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data));
};

export const currency = (Option = {}) => {
    return httpClient
        .get("/api/branches/currencies", Option)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data));
};

export const province = (Options = {}) => {
    return httpClient
        .get("/api/branches/provinces", Options)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data));
};

export const district = (province_code, Options = {}) => {
    return httpClient
        .get(`/api/branches/${province_code}/districts`, Options)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data));
};

export const commune = (district_code, Options = {}) => {
    return httpClient
        .get(`/api/branches/${district_code}/communes`, Options)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data));
};

export const village = (commune_code, Options = {}) => {
    return httpClient
        .get(`/api/branches/${commune_code}/villages`, Options)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data));
};
export const employee = (Options = {}) => {
    return httpClient
        .get("/api/branches/employees", Options)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data));
};
export const exportExcel = attributes => {
    return httpClient
        .post('/api/branches/export', attributes, {
            responseType: 'arraybuffer'
        })
        .then(response => Promise.resolve(response))
        .catch(error => Promise.reject(error.response.data))
}

export default {
    get,
    getExpireContract,
    store,
    find,
    update,
    destroy,
    uploadLogo,
    getLogo,
    currency,
    removeContract,
    province,
    district,
    commune,
    village,
    company,
    employee,
    updateContract,
    removeFile,
    exportExcel
};
