import itemOvertime from "@/api/payroll/item-overtime";
import { baseState, baseMutations } from "@/store/mixins";


export const state = {
    ...baseState,
};

export const getters = {};

export const mutations = {
    ...baseMutations
};

export const actions = {
    fetch(context, attributes = {}) {
        const params = Object.assign(
            {
                page: context.state.pagination.page,
                perPage: context.state.pagination.perPage,
                filter: {
                    search: context.state.search
                }
            },
            attributes
        );
        return itemOvertime.get({ params }).then(response => {
            context.commit("RECEIVE_RESOURCES", response);
            return Promise.resolve(response);
        });
    },

    store(context, data) {
        return itemOvertime.store(data);
    },

    update(context, { id, data }) {
        return itemOvertime.update(id, data);
    },

    destroy(context, id) {
        return itemOvertime.destroy(id);
    }
};
