import customerLoanPurpose from "@/api/credit-operation/customer-loan-purpose";

import { baseState, baseMutations } from "@/store/mixins";

export const state = {
	...baseState,
	loanSectors: []
};

export const getters = {};

export const mutations = {
	...baseMutations,
	SET_LOAN_SECTORS(state, data) {
		state.loanSectors = data;
},
};

export const actions = {
	fetch(context, attrubutes = {}) {
		let params = Object.assign(
			{
				page: context.state.pagination.page,
				perPage: context.state.pagination.perPage,
				filter: {
					search: context.state.search
				}
			},
			attrubutes
		);
		return customerLoanPurpose.get({ params }).then(response => {
			context.commit("RECEIVE_RESOURCES", response);
			return Promise.resolve(response);
		});
	},
	store(context, data) {
		return customerLoanPurpose.store(data);
	},
	update(context, { id, data }) {
		return customerLoanPurpose.update(id, data);
	},
	destroy(context, id) {
		return customerLoanPurpose.destroy(id);
	},
	async getLoanSector(context, attributes = {}) {
		let response = await customerLoanPurpose.loanSector({ params: attributes });
		context.commit("SET_LOAN_SECTORS", response.data);
},
};
