import Vue from 'vue'
import VueRouter from 'vue-router'
import routes from "./routes";
import VueCookies from 'vue-cookies'

Vue.use(VueRouter)

const router = new VueRouter({
    mode: 'history',
    scrollBehavior (to, from, savedPosition) {
        if (savedPosition) {
          return savedPosition
        } else {
          return { x: 0, y: 0 }
        }
    },
    routes // short for `routes: routes`
})

router.beforeEach((to, from, next) => {
    const authenticated = VueCookies.get('token');
    if (to.matched.some(route => route.meta.requiresAuth)) {
      if (authenticated) {
        next()
      } else {
        next({ name: 'login', query: { redirectTo: to.fullPath } })
      }
    } else if (to.matched.some(route => route.meta.requiresGuest)) {
      if (!authenticated) {
        next()
      } else {
        next({ name: 'home' })
      }
    } else {
      next()
    }
  })

export default router
