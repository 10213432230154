import jobDetail from "@/api/human-resource/job-detail";
import { baseState, baseMutations } from "@/store/mixins";

export const state = {
    ...baseState,
    positionLevels: [],
    jobTitles: [],
    reportToPositions: [],
    orgChartStructures: []
};

export const getters = {};

export const mutations = {
    ...baseMutations,
    SET_POSITOINLEVELS(state, data) {
        state.positionLevels = data;
    },
    SET_JOBTITLES(state, data) {
        state.jobTitles = data;
    },
    SET_REPORTTOPOSITIONS(state, data) {
        state.reportToPositions = data;
    },
    SET_ORGCHARSTRUCTURES(state, data) {
        state.orgChartStructures = data;
    }
};

export const actions = {
    fetch(context, attributes = {}) {
        const params = Object.assign(
            {
                page: context.state.pagination.page,
                perPage: context.state.pagination.perPage,
                filter: {
                    search: context.state.search
                }
            },
            attributes
        );
        return jobDetail.get({ params }).then(response => {
            context.commit("RECEIVE_RESOURCES", response);
            return Promise.resolve(response);
        });
    },

    store(context, data) {
        return jobDetail.store(data);
    },

    update(context, { id, data }) {
        return jobDetail.update(id, data);
    },

    destroy(context, id) {
        return jobDetail.destroy(id);
    },
    async getPositionLevel(context, attributes = {}) {
        let response = await jobDetail.positionLevel({
            params: attributes
        });
        context.commit("SET_POSITOINLEVELS", response.data);
    },
    async getJobTitle(context, attributes = {}) {
        let response = await jobDetail.jobTitle({
            params: attributes
        });
        context.commit("SET_JOBTITLES", response.data);
    },
    async getReportToPosition(context, attributes = {}) {
        let response = await jobDetail.reportToPosition({
            params: attributes
        });
        context.commit("SET_REPORTTOPOSITIONS", response.data);
    },
    async getOrgChartStructure(context, attributes = {}) {
        let response = await jobDetail.orgChartStructure({
            params: attributes
        });
        context.commit("SET_ORGCHARSTRUCTURES", response.data);
    }
};
