import customerLoanRequest from "@/api/credit-operation/customer-loan-request";
import { baseState, baseMutations } from "@/store/mixins";

import { isEmpty } from "lodash";

export const state = {
    ...baseState,
    customers: [],
    employees: [],
    bmEmployees: [],
    currencies: [],
    loanTypes: [],
    repaymentMethods: [],
    purpose: []
};

export const getters = {};

export const mutations = {
    ...baseMutations,
    SET_CUSTOMERS(state, data) {
        state.customers = data;
    },
    SET_EMPLOYEES(state, data) {
        state.employees = data;
    },
    SET_BM_EMPLOYEES(state, data) {
        state.bmEmployees = data;
    },
    SET_LOAN_TYPES(state, data) {
        state.loanTypes = data;
    },
    SET_CURRENCIES(state, data) {
        state.currencies = data;
    },
    SET_REPAYMENT_METHODS(state, data) {
        state.repaymentMethods = data;
    },
    SET_PURPOSE(state, data) {
        state.purpose = data;
    },
    CLEAR_RESOURCES(state) {
        state.loanTypes = [];
        state.currencies = [];
        state.customers = [];
        state.employees = [];
        state.bmEmployees = [];
        state.repaymentMethods = [];
        state.purpose = [];
    }
};

export const actions = {
    fetch(context, attributes = {}) {
        const params = Object.assign(
            {
                page: context.state.pagination.page,
                perPage: context.state.pagination.perPage,
                filter: {
                    search: context.state.search
                }
            },
            attributes
        );
        return customerLoanRequest.get({ params }).then(response => {
            context.commit("RECEIVE_RESOURCES", response);
            return Promise.resolve(response);
        });
    },

    store(context, data) {
        return customerLoanRequest.store(data);
    },

    async find(context, id) {
        if (isEmpty(context.state.edit_data)) {
            let response = await customerLoanRequest.find(id);
            context.commit("SET_EDIT_DATA", response.data);
        }
    },
    update(context, { id, data }) {
        return customerLoanRequest.update(id, data);
    },
    approve(context, { id, data }) {
        return customerLoanRequest.approve(id, data);
    },
    destroy(context, { id, data }) {
        return customerLoanRequest.destroy(id, data);
    },

    async getCustomer(context, attributes = {}) {
        const params = Object.assign(
            {
                page: context.state.pagination.page,
                perPage: context.state.pagination.perPage,
                filter: {
                    search: attributes.search
                }
            },
            attributes
        );
        let response = await customerLoanRequest.customer({ params });
        context.commit("SET_CUSTOMERS", response.data);
    },
    async getLoanType(context, attributes = {}) {
        let response = await customerLoanRequest.loanType({ params: attributes });
        context.commit("SET_LOAN_TYPES", response.data);
    },
    async getCurrency(context, attributes = {}) {
        let response = await customerLoanRequest.currency({ params: attributes });
        context.commit("SET_CURRENCIES", response.data);
    },
    async getRepaymentMethod(context, attributes = {}) {
        let response = await customerLoanRequest.repaymentMethod({
            params: attributes
        });
        context.commit("SET_REPAYMENT_METHODS", response.data);
    },
    async getPurpose(context, attributes = {}) {
        let response = await customerLoanRequest.purpose({ params: attributes });
        context.commit("SET_PURPOSE", response.data);
    },
    async getEmployees(context, attributes = {}) {
        const params = Object.assign(
            {
                page: context.state.pagination.page,
                perPage: context.state.pagination.perPage,
                filter: {
                    search: attributes.search,
                    status_id: 1
                }
            },
            attributes
        );
        let response = await customerLoanRequest.employee({ params });
        context.commit("SET_EMPLOYEES", response.data);
    },
    async getBmEmployees(context, attributes = {}) {
        const params = Object.assign(
            {
                page: context.state.pagination.page,
                perPage: context.state.pagination.perPage,
                filter: {
                    search: attributes.search,
                    status_id: 1
                }
            },
            attributes
        );
        let response = await customerLoanRequest.employee({ params });
        context.commit("SET_BM_EMPLOYEES", response.data);
    },
    previewPdf(context, id) {
        return customerLoanRequest.previewPdf(id)
    },
};
