import httpClient from "@/http-client";

export const get = (Options = {}) => {
    return httpClient
        .get("/api/loan-write-offs", Options)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data));
};

export const store = data => {
    return httpClient
        .post("/api/loan-write-offs", data)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data));
};

export const update = (id, data) => {
    return httpClient
        .put(`/api/loan-write-offs/${id}`, data)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data));
};

export const loanDisbursement = (Options = {}) => {
    return httpClient
        .get("/api/loan-write-offs/loan-disbursements", Options)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data));
};

export const writeOffType = (Option = {}) => {
    return httpClient
        .get("/api/loan-write-offs/write-off-types", Option)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data));
};

export default { get, store, update, loanDisbursement, writeOffType };
