import httpClient from "@/http-client";

export const employee = (options = {}) => {
    return httpClient
        .get("/api/reports/resources/employees", options)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data));
};
export const currency = (options = {}) => {
    return httpClient
        .get("/api/reports/resources/currencies", options)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data));
};
export const transactionType = (options = {}) => {
    return httpClient
        .get("/api/reports/resources/transaction-types", options)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data));
};

export const coaCategory = (Option = {}) => {
    return httpClient
        .get("/api/reports/resources/coa-categories", Option)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data));
};

export const coaSetup = (Option = {}) => {
    return httpClient
        .get("/api/reports/resources/coa-setups", Option)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data));
};

export const loanDisbursement = (Options = {}) => {
    return httpClient
        .get("/api/reports/resources/loan-disbursements", Options)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data));
};

export const userLogin = (options = {}) => {
    return httpClient
        .get("/api/reports/resources/user-login", options)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data));
};

export default {
    employee,
    currency,
    transactionType,
    coaCategory,
    coaSetup,
    loanDisbursement,
    userLogin
};
