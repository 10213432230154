import { baseState, baseMutations } from "@/store/mixins";
import PayslipTemplates from "@/api/payroll/payslip-template";
import { split } from "lodash";

export const state = {
    ...baseState,
    formViewDatas: []
};
export const getters = {};

export const mutations = {
    ...baseMutations,
    SET_VIEW_MODELS(state, { data, index }) {
        if (!index) {
            state.formViewDatas = data;
        } else {
            split(index, ",").forEach(el => {
                state.formViewDatas[el] = data[el];
            });
        }
    }
};
export const actions = {
    fetch(context, attributes = {}) {
        const params = Object.assign(
            {
                page: context.state.pagination.page,
                perPage: context.state.pagination.perPage,
                filter: {
                    search: context.state.search
                }
            },
            attributes
        );
        return PayslipTemplates.get({ params }).then(response => {
            context.commit("RECEIVE_RESOURCES", response);
            return Promise.resolve(response);
        });
    },
    store(context, data) {
        return PayslipTemplates.store(data);
    },
    update(context, { id, data }) {
        return PayslipTemplates.update(id, data);
    },
    destroy(context, id) {
        return PayslipTemplates.destroy(id);
    },
    async getFormViewData(context, { params, index }) {
        let response = await PayslipTemplates.formViewModel({ params: params });
        context.commit("SET_VIEW_MODELS", {
            data: response.data,
            index: index
        });
        return Promise.resolve(response);
    },
}