import nationality from "@/api/human-resource/nationality";
import { baseState, baseMutations } from "@/store/mixins";

export const state = {
    ...baseState
};

export const getters = {};

export const mutations = {
    ...baseMutations
};

export const actions = {
    fetch(context, attributes = {}) {
        const params = Object.assign(
            {
                page: context.state.pagination.page,
                perPage: context.state.pagination.perPage,
                filter: {
                    search: context.state.search
                }
            },
            attributes
        );
        return nationality.get({ params }).then(response => {
            context.commit("RECEIVE_RESOURCES", response);
            return Promise.resolve(response);
        });
    },

    store(context, data) {
        return nationality.store(data);
    },

    update(context, { id, data }) {
        return nationality.update(id, data);
    },

    destroy(context, id) {
        return nationality.destroy(id);
    }
};
