import httpClient from "@/http-client";

export const get = (options = {}) => {
    return httpClient
        .get("/api/companies", options)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data));
};

export const store = data => {
    return httpClient
        .post("/api/companies", data)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data));
};

export const find = (id, options = {}) => {
    return httpClient
        .get(`/api/companies/${id}`, options)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data));
};

export const update = (id, data) => {
    return httpClient
        .put(`/api/companies/${id}`, data)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data));
};

export const destroy = id => {
    return httpClient
        .delete(`/api/companies/${id}`)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data));
};

export const uploadLogo = file => {
    return httpClient
        .post("/api/companies/logos", file)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data));
};

export const getLogo = id => {
    return httpClient
        .get(`/api/companies/${id}/logos`, {
            responseType: "arraybuffer"
        })
        .then(response =>
            Promise.resolve(
                `data:image/jpg;base64,${new Buffer(
                    response.data,
                    "binary"
                ).toString("base64")}`
            )
        )
        .catch(error => Promise.reject(error.response.data));
};

export default { get, store, find, update, destroy, uploadLogo, getLogo };
