<template>
    <div
        class="tw-fixed tw-w-full tw-h-full tw-flex tw-flex-col tw-top-0 tw-left-0 tw-bottom-0 tw-right-0"
        style="z-index: 99998"
    >
        <div>
            <!-- begin login-cover -->
            <div class="login login-v2 fw-bold">
                <div class="login-cover">
                    <div
                        class="login-cover-img"
                        v-bind:style="{
                            backgroundImage: 'url(' + bg.activeImg + ')'
                        }"
                    ></div>
                    <div class="login-cover-bg"></div>
                </div>
                <!-- end login-cover -->

                <!-- begin login -->
                <div
                    class="login-container"
                    data-pageload-addclass="animated fadeIn"
                >
                    <!-- begin brand -->
                    <div class="login-header">
                        <div class="brand tw-text-center">
                            <div class="tw-w-full tw-flex tw-justify-center">
                                <img
                                    class="tw-rounded-full tw-w-48 tw-h-48 tw-flex tw-items-center tw-justify-center tw-object-cover"
                                    :src="photo"
                                    @error="onError"
                                    alt=""
                                />
                            </div>
                            <b class="tw-uppercase">{{ name }}</b>
                        </div>
                        <!-- <div class="icon">
            <i class="fa fa-lock"></i>
          </div> -->
                    </div>
                    <!-- end brand -->
                    <!-- begin login-content -->
                    <div class="login-content">
                        <form @submit.prevent="login" class="margin-bottom-0">
                            <div class="form-floating mb-20px">
                                <input
                                    :type="input_type"
                                    class="form-control fs-13px h-45px border-0"
                                    placeholder="Password"
                                    required
                                    v-model.trim="model.password"
                                    @keyup.enter="onUnlock"
                                    ref="password"
                                />
                                <label
                                    for="password"
                                    class="d-flex align-items-center text-gray-600 fs-13px"
                                    >Password</label
                                >
                                <a href="#" @click.prevent="showPassword">
                                    <i
                                        v-if="input_type === 'text'"
                                        class="toggle-password fas fa-eye"
                                    ></i>
                                    <i
                                        v-else
                                        class="toggle-password fa fa-fw fa-eye-slash"
                                    ></i>
                                </a>
                            </div>
                            <div
                                class="alert alert-danger fade show m-b-20"
                                v-if="errors.has('password')"
                            >
                                {{ errors.first('password') }}
                            </div>
                            <div class="login-buttons">
                                <ts-button
                                    type="submit"
                                    @click.prevent="onUnlock"
                                    class="btn btn-success d-block w-100 h-45px btn-lg"
                                    :waiting="waiting"
                                >
                                    Sign me in
                                </ts-button>
                            </div>
                            <div class="tw-mt-4 text-gray-500">
                                POWERED BY: TECHSTORE CREATIVE SOLUTIONS
                            </div>
                        </form>
                    </div>
                    <!-- end login-content -->
                </div>
                <!-- end login -->
            </div>

            <!-- begin login-bg -->
            <ul class="login-bg-list clearfix">
                <li
                    v-bind:class="{ active: bg.bg1.active }"
                    class="login-bg-list-item"
                >
                    <a
                        href="javascript:;"
                        v-on:click="select('bg1')"
                        v-bind:style="{
                            backgroundImage: 'url(' + bg.bg1.img + ')'
                        }"
                        class="login-bg-list-link"
                    ></a>
                </li>
                <li
                    v-bind:class="{ active: bg.bg2.active }"
                    class="login-bg-list-item"
                >
                    <a
                        href="javascript:;"
                        v-on:click="select('bg2')"
                        v-bind:style="{
                            backgroundImage: 'url(' + bg.bg2.img + ')'
                        }"
                        class="login-bg-list-link"
                    ></a>
                </li>
                <li
                    v-bind:class="{ active: bg.bg3.active }"
                    class="login-bg-list-item"
                >
                    <a
                        href="javascript:;"
                        v-on:click="select('bg3')"
                        v-bind:style="{
                            backgroundImage: 'url(' + bg.bg3.img + ')'
                        }"
                        class="login-bg-list-link"
                    ></a>
                </li>
                <li
                    v-bind:class="{ active: bg.bg4.active }"
                    class="login-bg-list-item"
                >
                    <a
                        href="javascript:;"
                        v-on:click="select('bg4')"
                        v-bind:style="{
                            backgroundImage: 'url(' + bg.bg4.img + ')'
                        }"
                        class="login-bg-list-link"
                    ></a>
                </li>
                <li
                    v-bind:class="{ active: bg.bg5.active }"
                    class="login-bg-list-item"
                >
                    <a
                        href="javascript:;"
                        v-on:click="select('bg5')"
                        v-bind:style="{
                            backgroundImage: 'url(' + bg.bg5.img + ')'
                        }"
                        class="login-bg-list-link"
                    ></a>
                </li>
                <li
                    v-bind:class="{ active: bg.bg6.active }"
                    class="login-bg-list-item"
                >
                    <a
                        href="javascript:;"
                        v-on:click="select('bg6')"
                        v-bind:style="{
                            backgroundImage: 'url(' + bg.bg6.img + ')'
                        }"
                        class="login-bg-list-link"
                    ></a>
                </li>
                <li
                    v-bind:class="{ active: bg.bg7.active }"
                    class="login-bg-list-item"
                >
                    <a
                        href="javascript:;"
                        v-on:click="select('bg7')"
                        v-bind:style="{
                            backgroundImage: 'url(' + bg.bg7.img + ')'
                        }"
                        class="login-bg-list-link"
                    ></a>
                </li>
                <li
                    v-bind:class="{ active: bg.bg8.active }"
                    class="login-bg-list-item"
                >
                    <a
                        href="javascript:;"
                        v-on:click="select('bg8')"
                        v-bind:style="{
                            backgroundImage: 'url(' + bg.bg8.img + ')'
                        }"
                        class="login-bg-list-link"
                    ></a>
                </li>
                <li
                    v-bind:class="{ active: bg.bg9.active }"
                    class="login-bg-list-item"
                >
                    <a
                        href="javascript:;"
                        v-on:click="select('bg9')"
                        v-bind:style="{
                            backgroundImage: 'url(' + bg.bg9.img + ')'
                        }"
                        class="login-bg-list-link"
                    ></a>
                </li>
                <li
                    v-bind:class="{ active: bg.bg10.active }"
                    class="login-bg-list-item"
                >
                    <a
                        href="javascript:;"
                        v-on:click="select('bg10')"
                        v-bind:style="{
                            backgroundImage: 'url(' + bg.bg10.img + ')'
                        }"
                        class="login-bg-list-link"
                    ></a>
                </li>
            </ul>
            <!-- end login-bg -->
        </div>
    </div>
</template>

<script>
import { Errors } from 'form-backend-validation'
import { mapActions } from 'vuex'
import VueCookies from 'vue-cookies'

export default {
    name: 'TsLockScreen',
    data () {
        return {
            default_user: require('../../assets/default_user.png'),
            waiting: false,
            errors: new Errors(),
            input_type: 'password',
            model: {
                password: null
            },
            bg: {
                activeImg: require('@/assets/img/login-bg/login-bg-10.jpg'),
                bg1: {
                    active: false,
                    img: require('@/assets/img/login-bg/login-bg-1.jpg')
                },
                bg2: {
                    active: false,
                    img: require('@/assets/img/login-bg/login-bg-2.jpg')
                },
                bg3: {
                    active: false,
                    img: require('@/assets/img/login-bg/login-bg-3.jpg')
                },
                bg4: {
                    active: false,
                    img: require('@/assets/img/login-bg/login-bg-4.jpg')
                },
                bg5: {
                    active: false,
                    img: require('@/assets/img/login-bg/login-bg-5.jpg')
                },
                bg6: {
                    active: false,
                    img: require('@/assets/img/login-bg/login-bg-6.jpg')
                },
                bg7: {
                    active: false,
                    img: require('@/assets/img/login-bg/login-bg-7.jpg')
                },
                bg8: {
                    active: false,
                    img: require('@/assets/img/login-bg/login-bg-8.jpg')
                },
                bg9: {
                    active: false,
                    img: require('@/assets/img/login-bg/login-bg-9.jpg')
                },
                bg10: {
                    active: true,
                    img: require('@/assets/img/login-bg/login-bg-10.jpg')
                }
            }
        }
    },
    mounted () {
        this.$refs.password.focus()
    },
    computed: {
        name () {
            return this.$store.state.authUser.user.employee
                ? this.$store.state.authUser.user.employee.employee_name_en
                : this.$store.state.authUser.user.user_name
        },
        photo () {
            let p = this.$store.state.authUser.user.employee
                ? this.$store.state.authUser.user.employee.photo
                : null
            if (p) {
                return p
            }
            return this.default_user
        }
    },
    methods: {
        ...mapActions('auth/user', ['unlock']),
        select: function (x) {
            this.bg.bg1.active = false
            this.bg.bg2.active = false
            this.bg.bg3.active = false
            this.bg.bg4.active = false
            this.bg.bg5.active = false
            this.bg.bg6.active = false
            this.bg.bg7.active = false
            this.bg.bg8.active = false
            this.bg.bg9.active = false
            this.bg.bg10.active = false

            this.$refs.password.focus()

            switch (x) {
                case 'bg1':
                    this.bg.bg1.active = true
                    this.bg.activeImg = this.bg.bg1.img
                    break
                case 'bg2':
                    this.bg.bg2.active = true
                    this.bg.activeImg = this.bg.bg2.img
                    break
                case 'bg3':
                    this.bg.bg3.active = true
                    this.bg.activeImg = this.bg.bg3.img
                    break
                case 'bg4':
                    this.bg.bg4.active = true
                    this.bg.activeImg = this.bg.bg4.img
                    break
                case 'bg5':
                    this.bg.bg5.active = true
                    this.bg.activeImg = this.bg.bg5.img
                    break
                case 'bg6':
                    this.bg.bg6.active = true
                    this.bg.activeImg = this.bg.bg6.img
                    break
                case 'bg7':
                    this.bg.bg7.active = true
                    this.bg.activeImg = this.bg.bg7.img
                    break
                case 'bg8':
                    this.bg.bg8.active = true
                    this.bg.activeImg = this.bg.bg8.img
                    break
                case 'bg9':
                    this.bg.bg9.active = true
                    this.bg.activeImg = this.bg.bg9.img
                    break
                case 'bg10':
                    this.bg.bg10.active = true
                    this.bg.activeImg = this.bg.bg10.img
                    break
            }
        },
        onUnlock () {
            this.waiting = true
            this.errors = new Errors()
            this.unlock(this.model)
                .then(() => {
                    this.$store.commit('setIsLock', false)
                    this.$emit('reset')
                })
                .catch(error => {
                    this.errors = new Errors(error.errors)
                    if (error.message == 'Unauthenticated.') {
                        this.$store.commit('setIsLock', false)
                        this.$emit('reset')
                        this.logout()
                    }
                })
                .finally(() => {
                    this.waiting = false
                    this.model.password = null
                })
        },
        logout () {
            VueCookies.remove('token')
            VueCookies.remove('refresh_token')
            this.$router.push({ name: 'login' })
        },
        onError () {
            event ? (event.target.src = this.default_user) : ''
        },
        showPassword () {
            if (this.input_type === 'password') {
                this.input_type = 'text'
            } else {
                this.input_type = 'password'
            }
        }
    }
}
</script>
<style scoped>
.login-cover-bg {
    background: -webkit-gradient(
        linear,
        left top,
        left bottom,
        from(rgba(0, 0, 0, 0.45)),
        to(rgba(0, 0, 0, 0.9))
    );
}
.toggle-password {
    float: right;
    cursor: pointer;
    margin-right: 10px;
    margin-top: -30px;
    font-size: 15px;
}
</style>
