import httpClient from '@/http-client'

export const get = (options = {}) => {
  return httpClient
    .get('/api/payroll-templates', options)
    .then(response => Promise.resolve(response.data))
    .catch(error => Promise.reject(error.response.data))
}

export const store = data => {
  return httpClient
    .post('/api/payroll-templates', data)
    .then(response => Promise.resolve(response.data))
    .catch(error => Promise.reject(error.response.data))
}

export const find = (id, options = {}) => {
  return httpClient
    .get(`/api/payroll-templates/${id}`, options)
    .then(response => Promise.resolve(response.data))
    .catch(error => Promise.reject(error.response.data))
}

export const update = (id, data) => {
  return httpClient
    .put(`/api/payroll-templates/${id}`, data)
    .then(response => Promise.resolve(response.data))
    .catch(error => Promise.reject(error.response.data))
}

export const destroy = id => {
  return httpClient
    .delete(`/api/payroll-templates/${id}`)
    .then(response => Promise.resolve(response.data))
    .catch(error => Promise.reject(error.response.data))
}

export const design = (id, options = {}) => {
  return httpClient
    .get(`/api/payroll-templates/design-templates/${id}`, { responseType: 'arraybuffer', ...options })
    .then(response => Promise.resolve(response))
    .catch(error => Promise.reject(error.response.data))
}

export const variable = (options = {}) => {
  return httpClient
    .get('/api/payroll-templates/system-variables', options)
    .then(response => Promise.resolve(response.data))
    .catch(error => Promise.reject(error.response.data))
}

export const uploadTemplate = (id, data) => {
  return httpClient
    .post(`/api/payroll-templates/upload-templates/${id}`, data)
    .then(response => Promise.resolve(response.data))
    .catch(error => Promise.reject(error.response.data))
}

export default {
  get,
  store,
  find,
  update,
  destroy,
  design,
  variable,
  uploadTemplate
}
