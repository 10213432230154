import { baseState, baseMutations } from '@/store/mixins'
import itemAllowance from '@/api//payroll/fixed-allowance'

export const state = {
    ...baseState,
    payrollItemAllowances: []
}
export const getters = {}

export const mutations = {
    ...baseMutations,
    SET_PAYROLL_ITEM_ALLOWANCE (state, payrollItemAllowances) {
        state.payrollItemAllowances = payrollItemAllowances
    }
}
export const actions = {
    store (context, data) {
        return itemAllowance.store(data)
    },
    fetch (context, attributes = {}) {
        const params = Object.assign(
            {
                page: context.state.pagination.page,
                perPage: context.state.pagination.perPage,
                filter: {
                    search: context.state.search
                }
            },
            attributes
        )
        return itemAllowance.get({ params }).then(response => {
            context.commit('RECEIVE_RESOURCES', response)
            return Promise.resolve(response)
        })
    },
    async getPayrollItemAllowance (context, attributes = {}) {
        let response = await itemAllowance.payrollItemAllowance({
            params: attributes
        })
        context.commit('SET_PAYROLL_ITEM_ALLOWANCE', response.data)
    },
    update (context, { id, data }) {
        return itemAllowance.update(id, data)
    },
    destroy (context, id) {
        return itemAllowance.destroy(id)
    },
    formViewModel (context, payload) {
        return itemAllowance.formViewModel(payload)
    },
    exportExcel (context, data) {
        return itemAllowance.exportExcel(data)
    },
    importExcel (context, data) {
        return itemAllowance.importExcel(data)
    }
}
