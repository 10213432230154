import attendanceDetail from "@/api/report/attendance-detail";
import { baseState, baseMutations } from "@/store/mixins";

export const state = {
    ...baseState,
    orgChartStructures: [],
    employees: []
};

export const getters = {};

export const mutations = {
    ...baseMutations,
    SET_ORG_CHART_STRUCTURE(state, data) {
        state.orgChartStructures = data;
    },
    CLEAR_EMPLOYEE(state) {
        state.employees = [];
    },
    SET_EMPLOYEE(state, data) {
        state.employees = data;
    }
};

export const actions = {
    fetch(context, attributes = {}) {
        const params = Object.assign(
            {
                page: context.state.pagination.page,
                perPage: context.state.pagination.perPage,
                filter: {
                    search: context.state.search
                }
            },
            attributes
        );
        return attendanceDetail.get({ params }).then(response => {
            context.commit("RECEIVE_RESOURCES", response);
            return Promise.resolve(response);
        });
    },

    async getOrgChartStructure(context, attributes = {}) {
        let response = await attendanceDetail.orgChartStructure({
            params: attributes
        });
        context.commit("SET_ORG_CHART_STRUCTURE", response.data);
    },
    async clearEmployee(context) {
        context.commit("CLEAR_EMPLOYEE");
    },
    async getEmployee(context, { id }) {
        let response = await attendanceDetail.employee(id);
        context.commit("SET_EMPLOYEE", response.data);
    }
};
