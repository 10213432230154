import { baseState, baseMutations } from "@/store/mixins";
import employeeProfile from "@/api/report/employee-profile";

export const state = {
    ...baseState,
};
export const getters = {};

export const mutations = {
    ...baseMutations,
    SET_STATUS(state, data) {
        state.status = data;
    },
};
export const actions = {
    employeeProfile(context, attributes = {}) {
        return employeeProfile.employeeProfile({
            params: Object.assign(
                {
                    template: localStorage.getItem('langCode')
                },
                attributes
            )
        })
    },
    getFormViewData(context, payload) {
        return employeeProfile.formViewModel(payload);
    },
    exportExcel(context, params) {
        return employeeProfile.exportExcel(params)
    }
};
