import { baseState, baseMutations } from "@/store/mixins";
import itemAllowance from "@/api//payroll/item-allowance";

export const state = {
    ...baseState,
};
export const getters={};

export const mutations = {
    ...baseMutations,
};
export const actions = {
    store(context, data) {
        return itemAllowance.store(data);
    }, 
    fetch(context, attributes = {}) {
        const params = Object.assign(
            {
                page: context.state.pagination.page,
                perPage: context.state.pagination.perPage,
                filter: {
                    search: context.state.search
                }
            },
            attributes
        );
        return itemAllowance.get({ params }).then(response => {
            context.commit("RECEIVE_RESOURCES", response);
            return Promise.resolve(response);
        });
    }, 
    update(context, { id, data }) {
        return itemAllowance.update(id, data);
    },
    destroy (context, id) {
        return itemAllowance.destroy(id)
    },
}