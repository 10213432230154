import httpClient from "@/http-client";

export const get = (Options = {}) => {
    return httpClient
        .get("/api/loan-transfers", Options)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data));
};

export const store = data => {
    return httpClient
        .post("/api/loan-transfers", data)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data));
};

export const update = (id, data) => {
    return httpClient
        .put(`/api/loan-transfers/${id}`, data)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data));
};

export const destroy = (id, data) => {
    return httpClient
        .put(`/api/loan-transfers/${id}/delete`, data)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data));
};

export const loanDisbursement = (Options = {}) => {
    return httpClient
        .get("/api/loan-transfers/loan-disbursements", Options)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data));
};

export const employee = (Options = {}) => {
    return httpClient
        .get("/api/loan-transfers/employees", Options)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data));
};

export default { get, store, update, destroy, loanDisbursement, employee };
