<template>
  <div>
    <div>
      <img
        :src="cropObject.imageUrl == '' ? ' ' : cropObject.imageUrl"
        ref="photoElement"
        @load="onImageLoaded"
        style="width: 300px;"
      />
    </div>
    <div class="tw-text-right tw-pt-4">
      <ts-button color="primary" :waiting="cropping" @click="cropPhoto">
        Crop Photo
      </ts-button>
    </div>
  </div>
</template>

<script>
import Cropper from 'cropperjs'

export default {
  props: ['width', 'height', 'cropObject'],
  data() {
    return {
      cropper: null,
      cropping: false
    }
  },
  methods: {
    onImageLoaded() {
      if (this.cropper !== null) this.cropper.destroy()

      this.cropper = new Cropper(this.$refs.photoElement, {
        viewMode: 0,
        dragMode: 'move',
        aspectRatio: this.width / this.height,
        autoCropArea: 1,
        restore: false,
        guides: false,
        center: false,
        highlight: false,
        cropBoxMovable: true,
        cropBoxResizable: true,
        toggleDragModeOnDblclick: false,
        minContainerWidth: this.width ? this.width : 200,
        minContainerHeight: this.height ? this.height : 100
      })
    },
    cropPhoto() {
      this.cropping = true
      this.cropObject.canvas = this.cropper.getCroppedCanvas()
      this.cropping = false
      this.$emit('close')
    }
  }
}
</script>

<style lang="scss">
@import '~cropperjs/dist/cropper.css';
</style>
