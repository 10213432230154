import httpClient from '@/http-client'

export const get = (options = {}) => {
  return httpClient
    .get('/api/pay-user-variables', options)
    .then(response => Promise.resolve(response.data))
    .catch(error => Promise.reject(error.response.data))
}

export const store = data => {
  return httpClient
    .post('/api/pay-user-variables', data)
    .then(response => Promise.resolve(response.data))
    .catch(error => Promise.reject(error.response.data))
}

export const update = (id, data) => {
  return httpClient
    .put(`/api/pay-user-variables/${id}`, data)
    .then(response => Promise.resolve(response.data))
    .catch(error => Promise.reject(error.response.data))
}

export const destroy = id => {
  return httpClient
    .delete(`/api/pay-user-variables/${id}`)
    .then(response => Promise.resolve(response.data))
    .catch(error => Promise.reject(error.response.data))
}

export const getNewUserVariableName = () => {
  return httpClient
    .get('/api/pay-user-variables/get-new-user-variable-name')
    .then(response => Promise.resolve(response.data))
    .catch(error => Promise.reject(error.response.data))
}

export const checkUserVariableSyntax = formula => {
  return httpClient
    .post('/api/pay-user-variables/check-user-variable-syntax', { formula })
    .then(response => Promise.resolve(response.data))
    .catch(error => Promise.reject(error.response.data))
}

export const getSystemVariables = (options = {}) => {
  return httpClient
    .get('/api/pay-user-variables/pay-system-variables', options)
    .then(response => Promise.resolve(response.data))
    .catch(error => Promise.reject(error.response.data))
}

export const getUserVariables = (options = {}) => {
  return httpClient
    .get('/api/pay-user-variables/pay-user-variables', options)
    .then(response => Promise.resolve(response.data))
    .catch(error => Promise.reject(error.response.data))
}

export default { get, store, update, destroy, getNewUserVariableName, checkUserVariableSyntax, getSystemVariables, getUserVariables }
