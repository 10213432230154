import httpClient from "@/http-client";

export const get = (Options = {}) => {
    return httpClient
        .get("/api/position-hierarchies", Options)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data));
};
export const getChildrenPosition = (Options = {}) => {
    return httpClient
        .get("/api/position-hierarchies/children-positions", Options)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data));
};

export default { get, getChildrenPosition };
