export default [
    {
      path: '/admin/loan-disbursements',
      name: 'loan-disbursement',
      meta: { requiresAuth: true },
      component: () => import('@/pages/credit-operation/loan-disbursement/index.vue')
    },
    {
      path: '/admin/loan-disbursements/create',
      name: 'loan-disbursement-create',
      meta: { requiresAuth: true },
      component: () => import('@/pages/credit-operation/loan-disbursement/create.vue')
    },
    {
      path: '/admin/loan-disbursements/:id/edit',
      name: 'loan-disbursement-edit',
      meta: { requiresAuth: true },
      component: () => import('@/pages/credit-operation/loan-disbursement/edit.vue')
    }
  ]
