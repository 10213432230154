import httpClient from '@/http-client'

export const get = (options = {}) => {
  return httpClient
    .get('/api/payroll-calculations', options)
    .then(response => Promise.resolve(response.data))
    .catch(error => Promise.reject(error.response.data))
}

export const store = data => {
  return httpClient
    .post('/api/payroll-calculations', data)
    .then(response => Promise.resolve(response.data))
    .catch(error => Promise.reject(error.response.data))
}

export const formViewModel = (options = {}) => {
  return httpClient
      .get("/api/payroll-calculations/form-view-models", options)
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.reject(error.response.data));
};

export default { get, store, formViewModel }
