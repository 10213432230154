import shiftManagement from "@/api/human-resource/shift-management";
import { baseState, baseMutations } from "@/store/mixins";

import { isEmpty } from "lodash";
import moment from "moment";

export const state = {
    ...baseState
};

export const getters = {
    calculateTime: () => (first_in, first_out, second_in, second_out) => {
        var duration = "";
        var secondDuration = "";
        var totalDuration = "";
        var hours = "";
        var minutes = "";
        if (moment(first_in, "HH:mm") > moment(first_out, "HH:mm")) {
            duration = moment.duration(
                moment(
                    moment()
                        .add(1, "days")
                        .format("YYYY-MM-D ") + first_out
                ).diff(moment().format("YYYY-MM-D ") + first_in)
            );
        } else {
            duration = moment.duration(
                moment(first_out, "HH:mm").diff(moment(first_in, "HH:mm"))
            );
        }
        if (moment(second_in, "HH:mm") > moment(second_out, "HH:mm")) {
            secondDuration = moment.duration(
                moment(
                    moment()
                        .add(1, "days")
                        .format("YYYY-MM-D ") + second_out
                ).diff(moment().format("YYYY-MM-D ") + second_in)
            );
        } else {
            secondDuration = moment.duration(
                moment(second_out, "HH:mm").diff(moment(second_in, "HH:mm"))
            );
        }

        totalDuration = moment.duration(
            duration._milliseconds + secondDuration._milliseconds
        );
        hours = parseInt(totalDuration.asHours());
        minutes = parseInt(totalDuration.asMinutes()) - hours * 60;
        return {
            value: hours + "." + minutes,
            description: hours + " hour and " + minutes + " minutes."
        };
    }
};

export const mutations = {
    ...baseMutations
};

export const actions = {
    fetch(context, attributes = {}) {
        const params = Object.assign(
            {
                page: context.state.pagination.page,
                perPage: context.state.pagination.perPage,
                filter: {
                    search: context.state.search
                }
            },
            attributes
        );
        return shiftManagement.get({ params }).then(response => {
            context.commit("RECEIVE_RESOURCES", response);
            return Promise.resolve(response);
        });
    },

    store(context, data) {
        return shiftManagement.store(data);
    },

    async find(context, id) {
        if (isEmpty(context.state.edit_data)) {
            let response = await shiftManagement.find(id);
            context.commit("SET_EDIT_DATA", response.data);
        }
    },

    update(context, { id, data }) {
        return shiftManagement.update(id, data);
    },

    destroy(context, id) {
        return shiftManagement.destroy(id);
    }
};
