import httpClient from "@/http-client";

export const profitAndLoss = (options = {}) => {
    return httpClient
        .get("/api/reports/accountings/profit-and-losses", options)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data));
};
export const summaryOfActiveAccount = (options = {}) => {
    return httpClient
        .get("/api/reports/accountings/summary-of-active-accounts", options)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data));
};
export const trialBalance = (options = {}) => {
    return httpClient
        .get("/api/reports/accountings/trial-balances", options)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data));
};
export const balanceSheet = (options = {}) => {
    return httpClient
        .get("/api/reports/accountings/balance-sheets", options)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data));
};
export const generalJournalRecord = (options = {}) => {
    return httpClient
        .get("/api/reports/accountings/general-journal-records", options)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data));
};
export const generalLedger = (options = {}) => {
    return httpClient
        .get("/api/reports/accountings/general-ledgers", options)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data));
};

export const generalLedgerExportExcel = attributes => {
    return httpClient
        .post("/api/reports/accountings/general-ledgers/excel", attributes, {
            responseType: "arraybuffer"
        })
        .then(response => Promise.resolve(response))
        .catch(error => Promise.reject(error.response.data));
};
export const generalJournalRecordExportExcel = attributes => {
    return httpClient
        .post(
            "/api/reports/accountings/general-journal-records/excel",
            attributes,
            {
                responseType: "arraybuffer"
            }
        )
        .then(response => Promise.resolve(response))
        .catch(error => Promise.reject(error.response.data));
};
export const trialBalanceExportExcel = attributes => {
    return httpClient
        .post("/api/reports/accountings/trial-balances/excel", attributes, {
            responseType: "arraybuffer"
        })
        .then(response => Promise.resolve(response))
        .catch(error => Promise.reject(error.response.data));
};
export const balanceSheetExportExcel = attributes => {
    return httpClient
        .post("/api/reports/accountings/balance-sheets/excel", attributes, {
            responseType: "arraybuffer"
        })
        .then(response => Promise.resolve(response))
        .catch(error => Promise.reject(error.response.data));
};
export const profitAndLossExportExcel = attributes => {
    return httpClient
        .post("/api/reports/accountings/profit-and-losses/excel", attributes, {
            responseType: "arraybuffer"
        })
        .then(response => Promise.resolve(response))
        .catch(error => Promise.reject(error.response.data));
};
export const summaryOfActiveAccountExportExcel = attributes => {
    return httpClient
        .post(
            "/api/reports/accountings/summary-of-active-accounts/excel",
            attributes,
            {
                responseType: "arraybuffer"
            }
        )
        .then(response => Promise.resolve(response))
        .catch(error => Promise.reject(error.response.data));
};
export const summaryOfActiveAccountRawDataCSV = attributes => {
    return httpClient
        .post(
            "/api/reports/accountings/summary-of-active-accounts/excel",
            attributes
        )
        .then(response => Promise.resolve(response))
        .catch(error => Promise.reject(error.response.data));
};
export default {
    profitAndLoss,
    summaryOfActiveAccount,
    trialBalance,
    balanceSheet,
    generalJournalRecord,
    generalLedger,
    generalLedgerExportExcel,
    generalJournalRecordExportExcel,
    trialBalanceExportExcel,
    balanceSheetExportExcel,
    profitAndLossExportExcel,
    summaryOfActiveAccountRawDataCSV,
    summaryOfActiveAccountExportExcel
};
