import positionHierarchy from "@/api/human-resource/position-hierarchy";
import { baseState, baseMutations } from "@/store/mixins";

export const state = {
    ...baseState
};

export const getters = {};

export const mutations = {
    ...baseMutations
};

export const actions = {
    fetch(context, attributes = {}) {
        const params = Object.assign(
            {
                page: context.state.pagination.page,
                perPage: context.state.pagination.perPage,
                filter: {
                    search: context.state.search
                }
            },
            attributes
        );
        return positionHierarchy.get({ params }).then(response => {
            context.commit("RECEIVE_RESOURCES", response);
            return Promise.resolve(response);
        });
    },
    fetchChildrenPosition(context, attributes = {}) {
        const params = Object.assign(
            {
                page: context.state.pagination.page,
                perPage: context.state.pagination.perPage,
                filter: {
                    search: context.state.search
                }
            },
            attributes
        );
        return positionHierarchy.getChildrenPosition({ params }).then(response => {
            return Promise.resolve(response);
        });
    }
};
