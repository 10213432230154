export default [
    {
      path: '/admin/loan-repayments',
      name: 'loan-repayment',
      meta: { requiresAuth: true },
      component: () => import('@/pages/credit-operation/loan-repayment/index.vue')
    },
    {
      path: '/admin/loan-repayments/create',
      name: 'loan-repayment-create',
      meta: { requiresAuth: true },
      component: () => import('@/pages/credit-operation/loan-repayment/create.vue')
    },
    {
      path: '/admin/loan-repayments/group-repayment/create',
      name: 'loan-repayment-group-create',
      meta: { requiresAuth: true },
      component: () => import('@/pages/credit-operation/loan-repayment/group-repayment/create.vue')
    }
  ]
