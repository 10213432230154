import httpClient from "@/http-client";

export const get = (options = {}) => {
    return httpClient
        .get("/api/customers", options)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data));
};

export const store = data => {
    return httpClient
        .post("/api/customers", data)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data));
};

export const find = (id, options = {}) => {
    return httpClient
        .get(`/api/customers/${id}`, options)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data));
};

export const update = (id, data) => {
    return httpClient
        .put(`/api/customers/${id}`, data)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data));
};

export const destroy = id => {
    return httpClient
        .delete(`/api/customers/${id}`)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data));
};

export const photoUpload = data => {
    return httpClient
        .post("/api/customers/photos", data)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data));
};

export const province = (Options = {}) => {
    return httpClient
        .get("/api/customers/provinces", Options)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data));
};

export const district = (province_code, Options = {}) => {
    return httpClient
        .get(`/api/customers/${province_code}/districts`, Options)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data));
};

export const commune = (district_code, Options = {}) => {
    return httpClient
        .get(`/api/customers/${district_code}/communes`, Options)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data));
};

export const village = (commune_code, Options = {}) => {
    return httpClient
        .get(`/api/customers/${commune_code}/villages`, Options)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data));
};

export const documentType = (Options = {}) => {
    return httpClient
        .get("/api/customers/document-types", Options)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data));
};

export const occupation = (Options = {}) => {
    return httpClient
        .get("/api/customers/occupations", Options)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data));
};

export const removeFile = (Options = {}) => {
    return httpClient
        .get("/api/customers/remove-files", Options)
        .then(response => Promise.resolve(response.data))
        .catch(response => Promise.reject(response.data));
};

export const exportExcel = attributes => {
    return httpClient
        .post("/api/customers/export-excel", attributes, {
            responseType: "arraybuffer"
        })
        .then(response => Promise.resolve(response))
        .catch(error => Promise.reject(error.response.data));
};
export const addVillage = data => {
    return httpClient
        .post("/api/customers/add-villages", data)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data));
};
export const moveBranch = data => {
    return httpClient
        .post("/api/customers/move-branches", data)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data));
};
export const branch = (Option = {}) => {
    return httpClient
        .get("/api/customers/branches", Option)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data));
};
export default {
    get,
    store,
    find,
    update,
    destroy,
    photoUpload,
    province,
    district,
    commune,
    village,
    documentType,
    removeFile,
    exportExcel,
    occupation,
    addVillage,
    moveBranch,
    branch
};
