export default [
    {
      path: '/admin/users',
      name: 'user',
      meta: { requiresAuth: true },
      component: () => import('@/pages/auth/user/index.vue')
    },
    {
      path: '/admin/users/create',
      name: 'user-create',
      meta: { requiresAuth: true },
      component: () => import('@/pages/auth/user/create.vue')
    },
    {
      path: '/admin/users/:id/edit',
      name: 'user-edit',
      meta: { requiresAuth: true },
      component: () => import('@/pages/auth/user/edit.vue')
    }
  ]
