import { baseState, baseMutations } from '@/store/mixins'
import summaryReportPensionFundAndInsurance from '@/api/report/summary-report-pension-fund-and-insurance'

export const state = {
    ...baseState
}
export const getters = {}

export const mutations = {
    ...baseMutations
}
export const actions = {
    fetch (context, attributes = {}) {
        const params = Object.assign(
            {
                page: context.state.pagination.page,
                perPage: context.state.pagination.perPage
            },
            attributes
        )
        return summaryReportPensionFundAndInsurance
            .view({ params })
            .then(response => {
                context.commit('RECEIVE_RESOURCES', response)
                return Promise.resolve(response)
            })
    },
    getFormViewData (context, payload) {
        return summaryReportPensionFundAndInsurance.formViewModel(payload)
    },
    previewPdf (context, attributes = {}) {
        return summaryReportPensionFundAndInsurance.previewPdf({
            params: Object.assign(
                {
                    template: localStorage.getItem('langCode')
                },
                attributes
            )
        })
    },
    exportExcel (context, data) {
        return summaryReportPensionFundAndInsurance.exportExcel(
            data
        )
    }
}
