import { flatten } from 'lodash'

const routes = (() => {
  const requireRoutes = require.context('.', true, /^((?!index).)*\.js$/)
  return flatten(
    requireRoutes.keys().map(fileName => requireRoutes(fileName).default)
  )
})()

export default routes
