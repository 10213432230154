import loanType from "@/api/credit-operation/loan-type";

import { baseState, baseMutations } from "@/store/mixins";

export const state = {
    ...baseState,
    currencies: [],
    repaymentMethods: []
};

export const getters = {};

export const mutations = {
    ...baseMutations,
    SET_CURRENCIES(state, data) {
        state.currencies = data;
    },
    SET_REPAYMENT_METHODS(state, data) {
        state.repaymentMethods = data;
    },
};

export const actions = {
    fetch(context, attrubutes = {}) {
        let params = Object.assign(
            {
                page: context.state.pagination.page,
                perPage: context.state.pagination.perPage,
                filter: {
                    search: context.state.search
                }
            },
            attrubutes
        );
        return loanType.get({ params }).then(response => {
            context.commit("RECEIVE_RESOURCES", response);
            return Promise.resolve(response);
        });
    },
    store(context, data) {
        return loanType.store(data);
    },
    update(context, { id, data }) {
        return loanType.update(id, data);
    },
    changeIsSkipSunday(context, { id, data }) {
        return loanType.changeIsSkipSunday(id, data);
    },
    changeIsSkipSaturday(context, { id, data }) {
        return loanType.changeIsSkipSaturday(id, data);
    },
    changeIsActive(context, { id, data }) {
        return loanType.changeIsActive(id, data);
    },
    async getCurrency(context, attributes = {}) {
        let response = await loanType.currency({ params: attributes });
        context.commit("SET_CURRENCIES", response.data);
    },
    async getRepaymentMethod(context, attributes = {}) {
        let response = await loanType.repaymentMethod({
            params: attributes
        });
        context.commit("SET_REPAYMENT_METHODS", response.data);
    },
};
