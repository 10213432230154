import httpClient from '@/http-client'

export const view = data => {
    return httpClient
        .get('/api/reports/summary-report-pension-fund-and-insurances', data)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data))
}
export const formViewModel = (options = {}) => {
    return httpClient
        .get(
            '/api/reports/summary-report-pension-fund-and-insurances/form-view-models',
            options
        )
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data))
}
export const previewPdf = data => {
    return httpClient
        .get(
            '/api/reports/summary-report-pension-fund-and-insurances/preview-pdf',
            data
        )
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data))
}
export const exportExcel = data => {
    return httpClient
        .post('/api/reports/summary-report-pension-fund-and-insurances/export-excel', data, {
            responseType: 'arraybuffer'
        })
        .then(response => Promise.resolve(response))
        .catch(error => Promise.resolve(error.response.data))
}
export default { formViewModel, view, previewPdf,exportExcel }
