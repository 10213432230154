import httpClient from '@/http-client'

export const get = (options = {}) => {
    return httpClient
        .get('/api/citizenship-statistics', options)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data))
}

export const province = (Options = {}) => {
    return httpClient
        .get('/api/citizenship-statistics/provinces', Options)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data))
}

export const district = (province_code, Options = {}) => {
    return httpClient
        .get(`/api/citizenship-statistics/${province_code}/districts`, Options)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data))
}

export const commune = (district_code, Options = {}) => {
    return httpClient
        .get(`/api/citizenship-statistics/${district_code}/communes`, Options)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data))
}

export const exportExcel = attributes => {
    return httpClient
        .post('/api/citizenship-statistics/export', attributes, {
            responseType: 'arraybuffer'
        })
        .then(response => Promise.resolve(response))
        .catch(error => Promise.reject(error.response.data))
}
export const importExcel = data => {
    return httpClient
        .post('/api/citizenship-statistics/import-excels', data)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data))
}
export default {
    get,
    province,
    district,
    commune,
    exportExcel,
    importExcel
}
