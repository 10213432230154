import httpClient from '@/http-client'

export const get = (options = {}) => {
    return httpClient
        .get('/api/monthly-deductions', options)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data))
}
export const employeeProfile = (Option = {}) => {
    return httpClient
        .get('/api/monthly-deductions/employee-profiles', Option)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data))
}
export const leaveRecord = (Option = {}) => {
    return httpClient
        .get('/api/monthly-deductions/leave-records', Option)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data))
}
export const installment = (Option = {}) => {
    return httpClient
        .get('/api/monthly-deductions/installments', Option)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data))
}
export const installmentDeduction = (Option = {}) => {
    return httpClient
        .get('/api/monthly-deductions/installment-deductions', Option)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data))
}
export const payItemDeduction = (Option = {}) => {
    return httpClient
        .get('/api/monthly-deductions/pay-item-deductions', Option)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data))
}
export const store = data => {
    return httpClient
        .post('/api/monthly-deductions', data)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data))
}
export const importUnpaidLeave = data => {
    return httpClient
        .post('/api/monthly-deductions/import-unpaid-leaves', data)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data))
}
export const importInstallment = data => {
    return httpClient
        .post('/api/monthly-deductions/import-installments', data)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data))
}
export const clearInstallment = data => {
    return httpClient
        .post('/api/monthly-deductions/clear-installments', data)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data))
}
export const update = (id, data) => {
    return httpClient
        .put(`/api/monthly-deductions/${id}`, data)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data))
}
export const destroy = id => {
    return httpClient
        .delete(`/api/monthly-deductions/${id}`)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data))
}

export default {
    employeeProfile,
    payItemDeduction,
    store,
    get,
    update,
    destroy,
    leaveRecord,
    installment,
    importUnpaidLeave,
    importInstallment,
    installmentDeduction,
    clearInstallment
}
