import { baseState, baseMutations } from "@/store/mixins";
import basicSalary from "@/api/human-resource/basic-salary";

export const state = {
    ...baseState,
    employeeProfiles: [],
    history: false
};
export const getters = {};

export const mutations = {
    ...baseMutations,
    SET_EMPLOYEE_PROFILES(state, employeeProfiles) {
        state.employeeProfiles = employeeProfiles;
    },
    SET_HISTORY(state, history) {
        state.history = history;
    }
}
export const actions = {
    store(context, data) {
        return basicSalary.store(data);
    },
    async getEmployeeProfile(context, attributes = {}) {
        let response = await basicSalary.employeeProfile({
            params: attributes
        });
        context.commit("SET_EMPLOYEE_PROFILES", response.data);
    },
    fetch(context, attributes = {}) {
        const params = Object.assign(
            {
                page: context.state.pagination.page,
                perPage: context.state.pagination.perPage,
                filter: {
                    search: context.state.search,
                    history: context.state.history
                }
            },
            attributes
        );
        return basicSalary.get({ params }).then(response => {
            context.commit("RECEIVE_RESOURCES", response);
            return Promise.resolve(response);
        });
    },
    update(context, { id, data }) {
        return basicSalary.update(id, data);
    },
    destroy(context, id) {
        return basicSalary.destroy(id);
    },
    getFormViewData(context, payload) {
        return basicSalary.formViewModel(payload);
    },
    exportExcel(context, data) {
        return basicSalary.exportExcel(data)
    },
    importExcel(context, data) {
        return basicSalary.importExcel(data)
    },
}
