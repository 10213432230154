import { baseState, baseMutations } from "@/store/mixins";
import monthlyCompensate from "@/api//payroll/monthly-compensate";

export const state = {
    ...baseState
};
export const getters={};

export const mutations = {
    ...baseMutations
};
export const actions = {
    getEmployeeList (context, attributes = {}) {
        return monthlyCompensate.getEmployeeList({
            params: attributes
        })
    },
    store(context, data) {
        return monthlyCompensate.store(data);
    }, 
    fetch(context, attributes = {}) {
        const params = Object.assign(
            {
                page: context.state.pagination.page,
                perPage: context.state.pagination.perPage,
                filter: {
                    search: context.state.search
                }
            },
            attributes
        );
        return monthlyCompensate.get({ params }).then(response => {
            context.commit("RECEIVE_RESOURCES", response);
            return Promise.resolve(response);
        });
    }, 
    update(context, { id, data }) {
        return monthlyCompensate.update(id, data);
    },
    destroy(context, id) {
        return monthlyCompensate.destroy(id);
    },
    formViewModel(context, payload) {
        return monthlyCompensate.formViewModel(payload);
    },
    importAnnualLeaveRemaining(context, data) {
        return monthlyCompensate.importAnnualLeaveRemaining(data)
    },
    importSalaryBackPay(context, data) {
        return monthlyCompensate.importSalaryBackPay(data)
    },
    importAllowanceBackPay(context, data) {
        return monthlyCompensate.importAllowanceBackPay(data)
    }
}