export default [
    {
        path: "/admin/report/daily-collection-schedules",
        name: "report-daily-collection-schedule",
        meta: { requiresAuth: true },
        component: () =>
            import(
                "@/pages/report/credit-operation/daily-collection-schedule.vue"
            )
    },
    {
        path: "/admin/report/disbursement-lists",
        name: "report-disbursement-list",
        meta: { requiresAuth: true },
        component: () =>
            import("@/pages/report/credit-operation/disbursement-list.vue")
    },
    {
        path: "/admin/report/staff-loans",
        name: "report-staff-loan",
        meta: { requiresAuth: true },
        component: () =>
            import("@/pages/report/credit-operation/staff-loan.vue")
    },
    {
        path: "/admin/report/summary-of-portfolio-at-risks",
        name: "report-summary-of-portfolio-at-risk",
        meta: { requiresAuth: true },
        component: () =>
            import(
                "@/pages/report/credit-operation/summary-of-portfolio-at-risk.vue"
            )
    },
    {
        path: "/admin/report/portfolio-repayments",
        name: "report-portfolio-repayment",
        meta: { requiresAuth: true },
        component: () =>
            import("@/pages/report/credit-operation/portfolio-repayment.vue")
    },
    {
        path: "/admin/report/loan-overdues",
        name: "report-loan-overdue",
        meta: { requiresAuth: true },
        component: () =>
            import("@/pages/report/credit-operation/loan-overdue.vue")
    },
    {
        path: "/admin/report/loan-transaction-summaries",
        name: "report-loan-transaction-summary",
        meta: { requiresAuth: true },
        component: () =>
            import(
                "@/pages/report/credit-operation/loan-transaction-summary.vue"
            )
    },
    {
        path: "/admin/report/loan-transaction-details",
        name: "report-loan-transaction-detail",
        meta: { requiresAuth: true },
        component: () =>
            import(
                "@/pages/report/credit-operation/loan-transaction-detail.vue"
            )
    },
    {
        path: "/admin/report/customer-statements",
        name: "report-customer-statement",
        meta: { requiresAuth: true },
        component: () =>
            import("@/pages/report/credit-operation/customer-statement.vue")
    },
    {
        path: "/admin/report/loan-written-offs",
        name: "report-loan-written-off",
        meta: { requiresAuth: true },
        component: () =>
            import("@/pages/report/credit-operation/loan-written-off.vue")
    },
    {
        path: "/admin/report/loan-recoveries",
        name: "report-loan-recovery",
        meta: { requiresAuth: true },
        component: () =>
            import("@/pages/report/credit-operation/loan-recovery.vue")
    },
    {
        path: "/admin/report/loan-drop-outs",
        name: "loan-drop-out",
        meta: { requiresAuth: true },
        component: () =>
            import("@/pages/report/credit-operation/loan-drop-out.vue")
    },
    {
        path: "/admin/report/loan-active-by-co",
        name: "loan-active-by-co",
        meta: { requiresAuth: true },
        component: () =>
            import("@/pages/report/credit-operation/loan-active-by-co.vue")
    },
    {
        path: "/admin/report/loan-paid-off-by-co",
        name: "loan-paid-off-by-co",
        meta: { requiresAuth: true },
        component: () =>
            import("@/pages/report/credit-operation/loan-paid-off-by-co.vue")
    },
    {
        path: "/admin/report/incentive-performance-by-co",
        name: "incentive-performance-by-co",
        meta: { requiresAuth: true },
        component: () =>
            import("@/pages/report/credit-operation/incentive-performance-by-co.vue")
    },
    {
        path: "/admin/report/device-posting-logs",
        name: "device-posting-log",
        meta: { requiresAuth: true },
        component: () =>
            import("@/pages/report/credit-operation/device-posting-log.vue")
    },
    {
        path: "/admin/report/re-disbursement-leads",
        name: "re-disbursement-leads",
        meta: { requiresAuth: true },
        component: () =>
            import("@/pages/report/credit-operation/re-disbursement-lead.vue")
    },
    {
        path: "/admin/report/cash-managements",
        name: "cash-management-reports",
        meta: { requiresAuth: true },
        component: () =>
            import("@/pages/report/credit-operation/cash-management-report.vue")
    },
    {
        path: "/admin/report/loan-summary-by-customers",
        name: "report-loan-summary-by-customer",
        meta: { requiresAuth: true },
        component: () =>
            import(
                "@/pages/report/credit-operation/loan-summary-by-customer.vue"
            )
    },
];
