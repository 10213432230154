import httpClient from "@/http-client";

export const get = (Options = {}) => {
    return httpClient
        .get("/api/holiday", Options)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data));
};

export const store = data => {
    return httpClient
        .post("/api/holiday", data)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data));
};

export const generate = data => {
    return httpClient
        .post("/api/holiday/generate", data)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data));
};

export const update = (id, data) => {
    return httpClient
        .put(`/api/holiday/${id}`, data)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data));
};

export default {
    get,
    store,
    update,
    generate
};
