import taxRang from '@/api/payroll/tax-range'
import { baseState, baseMutations } from '@/store/mixins'

export const state = {
    ...baseState,
    taxRangeMains:[]

}
export const getters = {}
export const mutations = {
    ...baseMutations,
    SET_IAX_RANGE_MAINS (state,taxRangeMains){
        state.taxRangeMains = taxRangeMains;
    }

}
export const actions = {
    fetch (context, attributes = {}) {
        const params = Object.assign(
            {
                page: context.state.pagination.page,
                perPage: context.state.pagination.perPage,
            },
            attributes
        )
        return taxRang.get({ params }).then(response => {
            context.commit('RECEIVE_RESOURCES', response)
            return Promise.resolve(response)
        })
    },
    async getTaxRangeFilter(context, attributes = {}) {
        let response = await taxRang.taxRangeMain({
            params: attributes
        })
        context.commit('SET_IAX_RANGE_MAINS', response.data)
    },
    store (context, data) {
        return taxRang.store(data)
    },
}