import {
  transform,
  groupBy,
  uniq,
  map,
  head,
  capitalize,
  sortBy,
  lowerCase,
  isEmpty
} from 'lodash'
import Role from '@/api/authorize/role-api'
import { baseState, baseMutations } from '@/store/mixins'

const getPermissionOrder = suffix => {
  switch (lowerCase(suffix)) {
    case 'view':
      return 0

    case 'create':
      return 1

    case 'update':
      return 2

    case 'delete':
      return 3

    default:
      return 4
  }
}

export const state = {
  ...baseState,
  permissions: [],
  searchPermissionText: ''
}

export const getters = {
  permissionGroups: state => {
    const permissions = state.permissions.filter(p => {
      return (
        p.name
          .toLowerCase()
          .includes(state.searchPermissionText.toLowerCase()) ||
        (p.group !== null &&
          p.group
            .toLowerCase()
            .includes(state.searchPermissionText.toLowerCase()))
      )
    })

    return transform(
      groupBy(permissions, permission => capitalize(permission.group)),
      (result, value, key) => {
        result[key] = groupBy(
          sortBy(value, p => getPermissionOrder(head(p.name.split('-')))),
          permission => capitalize(permission.resource)
        )
      },
      {}
    )
  },
  permissionColumns: state => {
    return sortBy(
      uniq(
        map(state.permissions, p => {
          return capitalize(head(p.name.split('-')))
        })
      ),
      p => getPermissionOrder(p)
    )
  }
}

export const mutations = {
  ...baseMutations,
  RECEIVE_PERMISSIONS(state, permissions) {
    state.permissions = permissions.map(permission => {
      return {
        id: permission.id,
        name: permission.name,
        resource: permission.label,
        group: permission.group
      }
    })
  },
  SET_SEARCH_PERMISSION(state, searchPermissionText) {
    state.searchPermissionText = searchPermissionText
  }
}

export const actions = {
  fetch(context, attributes = {}) {
    const params = Object.assign(
      {
        page: context.state.pagination.page,
        perPage: context.state.pagination.perPage,
        filter: {
          search: context.state.search
        },
        include: 'permissions'
      },
      attributes
    )

    return Role.get({ params }).then(response => {
      context.commit('RECEIVE_RESOURCES', response)
      return Promise.resolve(response)
    })
  },

  store(context, data) {
    return Role.store(data)
  },

  update(context, { id, ...data }) {
    return Role.update(id, data)
  },
  async find(context, id) {
    if (isEmpty(context.state.edit_data)) {
      let response = await Role.find(id)
      context.commit('SET_EDIT_DATA', response.data)
    }
  },
  destroy(context, id) {
    return Role.destroy(id).then(response => {
      let resource = context.state.resources.find(r => r.id == id)

      if (resource) {
        context.state.resources.splice(
          context.state.resources.indexOf(resource),
          1
        )
      }

      return response
    })
  },

  fetchPermissions(context, attributes) {
    return Role.permission({
      params: Object.assign({}, attributes)
    }).then(response => {
      context.commit('RECEIVE_PERMISSIONS', response.data)
      return Promise.resolve(response)
    })
  }
}
