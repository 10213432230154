import { baseState, baseMutations } from "@/store/mixins";
import payrollCycleDate from "@/api/human-resource/payroll-cycle-date";

export const state = {
    ...baseState
};
export const getters={};

export const mutations ={
    ...baseMutations
}
export const actions = {
    fetch(context, attributes = {}) {
        const params = Object.assign(
            {
                page: context.state.pagination.page,
                perPage: context.state.pagination.perPage,
                filter: {
                    search: context.state.search
                }
            },
            attributes
        );
        return payrollCycleDate.get({ params }).then(response => {
            context.commit("RECEIVE_RESOURCES", response);
            return Promise.resolve(response);
        });
    },
    update(context, { id, data }) {
        return payrollCycleDate.update(id, data);
    },
    destroy(context, id) {
        return payrollCycleDate.destroy(id);
    },
    store(context,data){
        return payrollCycleDate.store(data);
    }
}
