import httpClient from "@/http-client";

export const get = (Options = {}) => {
    return httpClient
        .get("/api/logical_positions", Options)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data));
};

export const store = data => {
    return httpClient
        .post("/api/logical_positions", data)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data));
};

export const update = (id, data) => {
    return httpClient
        .put(`/api/logical_positions/${id}`, data)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data));
};

export const destroy = id => {
    return httpClient
        .delete(`/api/logical_positions/${id}`)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data));
};

export const positionLevel = (Option = {}) => {
    return httpClient
        .get("/api/logical_positions/position-levels", Option)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data));
};

export const jobTitle = (Option = {}) => {
    return httpClient
        .get("/api/logical_positions/job-titles", Option)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data));
};

export const reportToPosition = (Option = {}) => {
    return httpClient
        .get("/api/logical_positions/report-to-positions", Option)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data));
};

export const orgChartStructure = (Option = {}) => {
    return httpClient
        .get("/api/logical_positions/orgChart-structures", Option)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data));
};
export default {
    get,
    store,
    update,
    destroy,
    positionLevel,
    jobTitle,
    reportToPosition,
    orgChartStructure
};
