import httpClient from "@/http-client";

export const get = (Options = {}) => {
    return httpClient
        .get("/api/journal-entries", Options)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data));
};

export const store = data => {
    return httpClient
        .post("/api/journal-entries", data)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data));
};

export const update = (id, data) => {
    return httpClient
        .put(`/api/journal-entries/${id}`, data)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data));
};

export const destroy = (id, data) => {
    return httpClient
        .put(`/api/journal-entries/${id}/delete`, data)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data));
};

export const currency = (Option = {}) => {
    return httpClient
        .get("/api/journal-entries/currencies", Option)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data));
};

export const coaCategory = (Option = {}) => {
    return httpClient
        .get("/api/journal-entries/coa-categories", Option)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data));
};

export const coaSetup = (Option = {}) => {
    return httpClient
        .get("/api/journal-entries/coa-setups", Option)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data));
};

export const transactionType = (options = {}) => {
    return httpClient
        .get("/api/journal-entries/transaction-types", options)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data));
};

export const refresh = data => {
    return httpClient
        .post("/api/journal-entries/refresh", data)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data));
};

export default { get, store, update, destroy, currency, coaCategory, coaSetup, transactionType, refresh };
