import resource from "@/api/report/resource";
import { baseState, baseMutations } from "@/store/mixins";
import { join } from "lodash"

export const state = {
    ...baseState,
    employees: [],
    transactionTypes: [],
    coaCategories: [],
    currencies: [],
    loanDisbursements: [],
    users: [],
};

export const getters = {};

export const mutations = {
    ...baseMutations,
    SET_EMPLOYEES(state, data) {
        state.employees = data;
    },
    SET_CURRENCIES(state, data) {
        state.currencies = data;
    },
    SET_TRANSACTION_TYPES(state, data) {
        state.transactionTypes = data;
    },
    SET_COA_CATEGORIES(state, data) {
        state.coaCategories = data;
    },
    SET_LAON_DISBURSEMENTS(state, data) {
        state.loanDisbursements = data;
    },
    SET_USERS(state, data) {
        state.users = data;
    },
};

export const actions = {
    async getEmployee(context, attributes = {}) {
        let response = await resource.employee({ params: attributes });
        context.commit("SET_EMPLOYEES", response.data);
    },
    async getCurrency(context, attributes = {}) {
        let response = await resource.currency({ params: attributes });
        context.commit("SET_CURRENCIES", response.data);
    },
    async getTransactionType(context, attributes = {}) {
        let response = await resource.transactionType({ params: attributes });
        context.commit("SET_TRANSACTION_TYPES", response.data);
    },
    async getCoaCategory(context, attributes = {}) {
        let response = await resource.coaCategory({ params: attributes });
        context.commit("SET_COA_CATEGORIES", response.data);
    },
    getCoaSetup(context, attributes = {}) {
        let params = Object.assign({
            filter: {
                search: attributes.search,
                acc_cat_id: attributes.acc_cat_id,
                currency_id: attributes.currency_id
            }
        });
        return resource.coaSetup({ params });
    },
    async getLoanDisbursement(context, attributes = {}) {
        const params = Object.assign(
            {
                page: context.state.pagination.page,
                perPage: context.state.pagination.perPage,
                filter: {
                    approve_status: 2, //Approved
                    co_id: join(attributes.employee_id, ","),
                    // is_write_off: false,
                }
            },
            attributes
        );
        let response = await resource.loanDisbursement({ params });
        context.commit("SET_LAON_DISBURSEMENTS", response.data);
    },
    async getUserLogin(context, attributes = {}) {
        let response = await resource.userLogin({ params: attributes });
        context.commit("SET_USERS", response.data);
    }
};
