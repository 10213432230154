export default [
    {
      path: '/admin/report/profit-and-losses',
      name: 'report-profit-and-loss',
      meta: { requiresAuth: true },
      component: () => import('@/pages/report/accounting/profit-and-loss.vue')
    },
    {
      path: '/admin/report/summary-of-active-accounts',
      name: 'report-summary-of-active-account',
      meta: { requiresAuth: true },
      component: () => import('@/pages/report/accounting/summary-of-active-account.vue')
    },
    {
      path: '/admin/report/trial-balances',
      name: 'report-trial-balance',
      meta: { requiresAuth: true },
      component: () => import('@/pages/report/accounting/trial-balance.vue')
    },
    {
      path: '/admin/report/balance-sheets',
      name: 'report-balance-sheet',
      meta: { requiresAuth: true },
      component: () => import('@/pages/report/accounting/balance-sheet.vue')
    },
    {
      path: '/admin/report/general-journal-records',
      name: 'report-general-journal-record',
      meta: { requiresAuth: true },
      component: () => import('@/pages/report/accounting/general-journal-record.vue')
    },
    {
      path: '/admin/report/general-ledgers',
      name: 'report-general-ledger',
      meta: { requiresAuth: true },
      component: () => import('@/pages/report/accounting/general-ledger.vue')
    }
  ]
