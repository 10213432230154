export default [
    {
        path: "/admin/companies",
        name: "company",
        meta: true,
        component: () => import("@/pages/corporate/company/index.vue")
    },
    {
        path: "/admin/company/create",
        name: "company-create",
        meta: true,
        component: () => import("@/pages/corporate/company/create.vue")
    },
    {
        path: "/admin/companies/:id/edit",
        name: "company-edit",
        meta: true,
        component: () => import("@/pages/corporate/company/edit.vue")
    }
];
