import { baseState, baseMutations } from '@/store/mixins'
import payslip from '@/api/report/payslip'

export const state = {
    ...baseState,
    payslipTemplates: [],
    employeeProfiles: [],
    payslip: []
}
export const getters = {}
export const mutations = {
    ...baseMutations,
    SET_PAYSLIP_TEMPLATES (state, payslipTemplates) {
        state.payslipTemplates = payslipTemplates
    },
    SET_EMPLOYEES (state, employeeProfiles) {
        state.employeeProfiles = employeeProfiles
    },
    SET_PAYSLIP (state, payslip) {
        state.payslip = payslip
    }
}
export const actions = {
    fetch (context, params = {}) {
        return payslip.view({ params }).then(response => {
            context.commit('SET_PAYSLIP', response.data)
            return Promise.resolve(response)
        })
    },
    async getPayslipTemplate (context, attributes = {}) {
        let response = await payslip.payslipTemplate({
            params: attributes
        })
        context.commit('SET_PAYSLIP_TEMPLATES', response.data)
    },
    async getEmployee (context, { id }) {
        let response = await payslip.employeeProfile(id)
        context.commit('SET_EMPLOYEES', response.data)
    },
    async formViewModel (context, payload) {
        return await payslip.formViewModel(payload)
    },
    exportExcel (context, { template_id, ...attributes }) {
        return payslip.exportExcel(template_id, attributes)
    },
    previewPdf (context, attributes = {}) {
        return payslip.previewPdf({
            params: Object.assign(
                {
                    template: localStorage.getItem('langCode')
                },
                attributes
            )
        })
    }
}
