import leaveRecord from "@/api/human-resource/leave-record";
import { baseState, baseMutations } from "@/store/mixins";
import { isEmpty } from "lodash";

export const state = {
    ...baseState,
    employees: [],
    leaveTypes: [],
    times: [],
    leaveTypeSubItems: []
};

export const getters = {};

export const mutations = {
    ...baseMutations,
    RECEIVE_TIMES(state, data) {
        state.times = data;
    },
    CLEAR_TIMES(state) {
        state.times = [];
    },
    SET_EMPLOYEE(state, data) {
        state.employees = data;
    },
    SET_LEAVE_TYPE(state, data) {
        state.leaveTypes = data;
    },
    SET_LEAVE_TYPE_SUB_ITEM(state, data) {
        state.leaveTypeSubItems = data;
    }
};

export const actions = {
    fetch(context, attributes = {}) {
        const params = Object.assign(
            {
                page: context.state.pagination.page,
                perPage: context.state.pagination.perPage,
                filter: {
                    search: context.state.search
                }
            },
            attributes
        );
        return leaveRecord.get({ params }).then(response => {
            context.commit("RECEIVE_RESOURCES", response);
            return Promise.resolve(response);
        });
    },

    store(context, data) {
        return leaveRecord.store(data);
    },
    async find(context, id) {
        if (isEmpty(context.state.edit_data)) {
            let response = await leaveRecord.find(id);
            context.commit("SET_EDIT_DATA", response.data);
        }
    },
    update(context, { id, data }) {
        return leaveRecord.update(id, data);
    },

    destroy(context, { id, data }) {
        return leaveRecord.destroy(id, data);
    },

    async getTimeFromTo(context, data) {
        if (!data.employee_id || !data.date_from || !data.date_to) return;
        let response = await leaveRecord.getTime(data);
        context.commit("RECEIVE_TIMES", response.data);
    },
    clearTimeFromTo(context) {
        context.commit("CLEAR_TIMES");
    },
    getTotal(context, data) {
        return leaveRecord.getTotalDay(data);
    },
    async getEmployee(context, attributes = {}) {
        let response = await leaveRecord.employee({
            params: attributes
        });
        context.commit("SET_EMPLOYEE", response.data);
    },
    async getLeaveType(context, attributes = {}) {
        let response = await leaveRecord.leaveType({
            params: attributes
        });
        context.commit("SET_LEAVE_TYPE", response.data);
    },
    async getLeaveTypeSubItem(context, attributes = {}) {
        let response = await leaveRecord.leaveTypeSubItem({
            params: attributes
        });
        context.commit("SET_LEAVE_TYPE_SUB_ITEM", response.data);
    }
};
