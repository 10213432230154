import holidayRule from "@/api/human-resource/holiday-rule";
import { baseState, baseMutations } from "@/store/mixins";

export const state = {
    ...baseState,
    substitute: []
};

export const getters = {};

export const mutations = {
    ...baseMutations,
    RECEIVE_SUBSTITUTE(state, substitute) {
        state.substitute = substitute;
    }
};

export const actions = {
    fetch(context, attributes = {}) {
        const params = Object.assign(
            {
                page: context.state.pagination.page,
                perPage: context.state.pagination.perPage,
                filter: {
                    search: context.state.search
                }
            },
            attributes
        );
        return holidayRule.get({ params }).then(response => {
            context.commit("RECEIVE_RESOURCES", response);
            return Promise.resolve(response);
        });
    },
    store(context, data) {
        return holidayRule.store(data);
    },

    async fetchSubstitute(context, attributes = {}) {
        let response = await holidayRule.substitute({ params: attributes });
        context.commit("RECEIVE_SUBSTITUTE", response.data);
    }
};
