import httpClient from '@/http-client'

export const get = (options = {}) => {
  return httpClient
    .get('/api/monthly-compensates', options)
    .then(response => Promise.resolve(response.data))
    .catch(error => Promise.reject(error.response.data))
}

export const store = data => {
  return httpClient
    .post('/api/monthly-compensates', data)
    .then(response => Promise.resolve(response.data))
    .catch(error => Promise.reject(error.response.data))
}

export const update = (id, data) => {
  return httpClient
    .put(`/api/monthly-compensates/${id}`, data)
    .then(response => Promise.resolve(response.data))
    .catch(error => Promise.reject(error.response.data))
}

export const getEmployeeList = (options = {}) => {
  return httpClient
    .get("/api/monthly-compensates/get-employee-lists", options)
    .then(response => Promise.resolve(response.data))
    .catch(error => Promise.reject(error.response.data))
}

export const destroy = id => {
  return httpClient
    .delete(`/api/monthly-compensates/${id}`)
    .then(response => Promise.resolve(response.data))
    .catch(error => Promise.reject(error.response.data));
}

export const formViewModel = (options = {}) => {
  return httpClient
    .get("/api/monthly-compensates/form-view-models", options)
    .then(response => Promise.resolve(response.data))
    .catch(error => Promise.reject(error.response.data));
}

export const importAnnualLeaveRemaining = data => {
  return httpClient
      .post('/api/monthly-compensates/import-annual-leave-remainings', data)
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.reject(error.response.data))
}
export const importSalaryBackPay = data => {
  return httpClient
      .post('/api/monthly-compensates/import-salary-back-pays', data)
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.reject(error.response.data))
}
export const importAllowanceBackPay = data => {
  return httpClient
      .post('/api/monthly-compensates/import-allowance-back-pays', data)
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.reject(error.response.data))
}

export default { get, store, update, destroy, formViewModel, getEmployeeList, importAnnualLeaveRemaining, importSalaryBackPay,importAllowanceBackPay }
