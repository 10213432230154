import httpClient from "@/http-client";

export const get = (options = {}) => {
    return httpClient
        .get("/api/fixed-allowances", options)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data));
};

export const store = data => {
    return httpClient
        .post("/api/fixed-allowances", data)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data));
};

export const update = (id, data) => {
    return httpClient
        .put(`/api/fixed-allowances/${id}`, data)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data));
};

export const destroy = id => {
    return httpClient
      .delete(`/api/fixed-allowances/${id}`)
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.reject(error.response.data));
};

export const formViewModel = (options = {}) => {
    return httpClient
        .get("/api/fixed-allowances/form-view-models", options)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data));
};
export const exportExcel = attributes => {
    return httpClient
        .post('/api/fixed-allowances/export', attributes, {
            responseType: 'arraybuffer'
        })
        .then(response => Promise.resolve(response))
        .catch(error => Promise.reject(error.response.data))
}
export const payrollItemAllowance = (Option = {}) => {
    return httpClient
        .get('/api/fixed-allowances/payroll-item-allowances', Option)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data))
}
export const importExcel = data => {
    return httpClient
        .post('/api/fixed-allowances/import-excels', data)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data))
}
export default {store,get,update,destroy, formViewModel,exportExcel,payrollItemAllowance,importExcel}