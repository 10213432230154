import { baseState, baseMutations } from "@/store/mixins";
import monthlyIncentiveBonus from "@/api//payroll/monthly-incentive-bonus";

export const state = {
    ...baseState
};
export const getters = {};

export const mutations = {
    ...baseMutations,
    SET_EMPLOYEE_LISTS(state, getEmployeeList) {
        state.getEmployeeList = getEmployeeList
    },
};
export const actions = {
    async getEmployeeList(context, attributes = {}) {
        let response = await monthlyIncentiveBonus.getEmployeeList({
            params: attributes
        })
        context.commit('SET_EMPLOYEE_LISTS', response.data)
    },
    store(context, data) {
        return monthlyIncentiveBonus.store(data);
    },
    fetch(context, attributes = {}) {
        const params = Object.assign(
            {
                page: context.state.pagination.page,
                perPage: context.state.pagination.perPage,
                filter: {
                    search: context.state.search
                }
            },
            attributes
        );
        return monthlyIncentiveBonus.get({ params }).then(response => {
            context.commit("RECEIVE_RESOURCES", response);
            return Promise.resolve(response);
        });
    },
    update(context, { id, data }) {
        return monthlyIncentiveBonus.update(id, data);
    },
    destroy(context, id) {
        return monthlyIncentiveBonus.destroy(id);
    },
    formViewModel(context, payload) {
        return monthlyIncentiveBonus.formViewModel(payload);
    },
    importExcel(context, data) {
        return monthlyIncentiveBonus.importExcel(data)
    },
    exportExcel(context, data) {
        return monthlyIncentiveBonus.exportExcel(data)
    },
}