import coaCategory from '@/api/accounting/coa-category'

import { baseState, baseMutations } from '@/store/mixins'

export const state = {
  ...baseState,
  categoryTypes: [
        'ASSETS',
        'REVENUES',
        'LIABILITIES',
        'EQUITIES',
        'EXPENSES',
  ]
}

export const getters = {}

export const mutations = {
  ...baseMutations
}

export const actions = {
  fetch(context, attrubutes = {}) {
    let params = Object.assign(
      {
        page: context.state.pagination.page,
        perPage: context.state.pagination.perPage,
        filter: {
          search: context.state.search
        }
      },
      attrubutes
    )
    return coaCategory.get({ params }).then(response => {
      context.commit('RECEIVE_RESOURCES', response)
      return Promise.resolve(response)
    })
  },
  store(context, data) {
    return coaCategory.store(data)
  },
  update(context, { id, data }) {
    return coaCategory.update(id, data)
  },
  changeIsBspl(context, { id, data }) {
    return coaCategory.changeIsBspl(id, data);
},
}
